/*
 * 定义常量
 * */
import React from "react";

import avatar from "../assets/default-avatar.png";
import badge0 from "../assets/badge/badge-00.png";
import badge1 from "../assets/badge/badge-01.png";
import badge3 from "../assets/badge/badge-03.png";
import badge5 from "../assets/badge/badge-05.png";
import badge6 from "../assets/badge/badge-06.png";
import badge7 from "../assets/badge/badge-07.png";
import badge8 from "../assets/badge/badge-08.png";
import badge9 from "../assets/badge/badge-09.png";
import badge10 from "../assets/badge/badge-10.png";
import badge12 from "../assets/badge/badge-12.png";
import badge15 from "../assets/badge/badge-15.png";
import badge16 from "../assets/badge/badge-16.png";
import * as statics from './static.js';
import * as invoice from "./constant/invoice";
import * as train from "../pages/train/constant";
import * as commission from "./constant/commission.jsx";
import * as update from "./constant/update";

export const { BUSINESS_CLASSIFICATION, BUSINESS_CLASSIFICATION_OBJ, INVOICE_TYPE, INVOICE_TYPE_OBJ } = invoice;
export const { DELIVERY_PROGRESS_OBJ } = train;
export const { COMMISSION_FORMULA, AMOUNT_KEY, CONTRACT_KEY, DEPARTMENT_KEY, TYPE_KEY, DATE_KEY, PROJECTNAME_KEY, SUMMARY_KEY, CUSTOMER_KEY, INVOICE_KEY } = commission;
export const AUTH = statics.AUTH;

export const AGENTID = statics.AGENTID;
export const CORPID = statics.CORPID;

export const APPID = statics.APPID;
export const REDIRECT_URI = statics.REDIRECT_URI;
const url = encodeURIComponent(REDIRECT_URI);
export const GOTO = encodeURIComponent(
    `https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${APPID}&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=${url}`
);
export const AMAP_KEY = statics.AMAP_KEY;

export const PAGE_SIZE = 50;

export const ROLE = {
    1: "管理员",
    2: "普通用户",
};

export const DD_ID = {
    SERVICE: "104596501", //安服部
    PRODUCT1: "104596498", //产品一部
    PRODUCT2: "104596499", //产品二部
    OPERATE: "104596503", //运营部
    OTHER: "104596496", //其它
    BUSINESS: "591877050",//安全意识部
    MANAGEMENT_CENTER: "501605326",//经管办
};
export const ROLES = {
    ADMIN: 1, //管理员
    COMMON: 2, //普通用户
    MOAADMIN: 3, //MOA管理员
    SELLERADMIN: 4, //销售管理
    SELLER: 5, //销售
    CTO: 6, //费用审批
    SERVICEADMIN: 7, //安服部审批
    FINANCE: 8, //财务总监
    MANAGER: 9, //公司管理
    TASK: 10, //任务负责人
    CONTRACT: 11, //合同管理操作员
    PRESELLER: 12, //项目经理技术支持
    HRBP: 13, //HRBP
    BUSINESS: 14, //事业部总监
    CAPTAIN: 15, //队长
    HRBP_ASSISTANT: 16, //HRBP助理
    CAPITAL: 17, //资金管理操作员
    AWARD: 18, //奖项管理操作员
    ACCOUNT: 19, //账号管理操作员
    CRMADMIN: 20, //CRM管理员
    CLOCK: 21, //打卡人员
    ACCOUNTADMIN: 22, //账号管理员
    CONTRACTADMIN: 23, //合同管理员
    TECHNOLOGY: 24, //合同技术审核员
    LAW: 25, //合同法务审核员
    STAMP: 26, //合同盖章员
    FINAL_APPROVE: 27, //最终合同审核员
    SCANNING: 28, //合同扫描上传员
    MEETING: 29, //会议管理操作员
    WEAPON: 30, //武器库录入人员
    PRODUCT: 31, //产品审批员
    SERVICE: 32, //服务审批员
    OPERATING: 33, //运营审批员
    OTHER_WEAPON: 34, //其他武器审批员
    PROJECT_CONSTRACT: 35, //项目合同管理员
    CRM_USER: 36, //CRM用户
    WIN_APPLYER: 37, //赢单审批
    RETURN_ADMIN: 38, //回款管理
    PACKAGE: 39, //发票快递员
    INVOICE_SERVICE: 40, //发票安服审批
    INVOICE_ENTER: 41, //发票信息录入
    SONG_CLOCK: 42, //松江打卡
    SEAL_ADMIN: 43, //印章管理员
    SEAL_FILE_ADMIN: 44, //印章文件管理员
    DEPARTMENT_ADMIN: 45, //商务部总经理
    CLOCK_ADMIN: 46, //打卡管理员
    COST_FINAL_ADMIN: 47, //成本最终审批员
    PROJECT_ADMIN: 48, //项目管理员
    AWARD_USER: 49, //奖项用户
    CLCLE: 50, //奖金赛轮询
    SUPERVISE: 51, //合同监督员
    PRESELLER_ADMIN: 52, //项目经理管理员
    MARKET_BUSINESS: 53, //费用总监审批
    MARKET_ADMIN: 54,//费用副总裁审批
    MARKET_FINANCE: 55,//费用财务审批
    MARKET_CC: 56,//费用申请抄送
    INVOICE_CC: 57,//发票信息抄送
    CONTRACT_APPLY: 58,//合同申请员
    DAILY_WORK_VIEWER: 59,//日报查看员
    JACK_PROJECT: 60,//玖克项目
    UNLOCK: 61,//项目解锁员
    COST_CHANNEL_ADMIN: 62, //成本渠道审批节点
    ASSISTANT_POSITION: 63, //暂定
    GO_OUT_APPROVE: 64, //出门卡审批员
    WEAPON_BUSINESS: 65, //安全意识审批员
    SPECIAL_APPROVER: 66, //特殊审批员
    PRODUCT1: 67,//产品一审批员
    MARKETCOST_FINAL: 68,//费用最终确认
    CONTRACT_APPROVE: 70,//合同文件审批
    CONTRACT_DOWNLOAD_NO_WATERMARK: 71,//合同下载无水印
    PROJECT_FINISHED: 72,//项目完结操作员
    PM_DAILYWORK_APPROVER: 73,//项目经理日报审批
    VENDOR_ADMIN: 74,//供应商管理员
    QUOTATION_APPROVER: 75,//报价单审批员
    ROYALTY: 76,//结算提成
    EQUIPMENT: 77,//设备管理
    RIRECTOR: 78,//商务总监
    PREADMIN: 80,//售前主管
    RIGHTS: 100, //后台管理员
    CEO: 82, //CEO
};

export const UPDATE_LIST = update.UPDATE_LIST(ROLES);

export const AVATAR = avatar;
export const UserInfoContext = React.createContext({});
export const MenuSide = React.createContext({});

export const SWITCH_ACCOUNT_WHITELIST = ["施纯利", "黎鑫", "Owen", "宋艾米", "李晓波", "宋秋云", "鲍晓南", "汤雨璇", "周芫园"];

/*
 * CRM相关常量
 * */
// 公司性质
export const COMPANY_NATURE = [
    {
        key: 0,
        value: 0,
        text: "未分类",
    },
    {
        key: 1,
        value: 1,
        text: "外商独资",
    },
    {
        key: 2,
        value: 2,
        text: "民营企业",
    },
    {
        key: 3,
        value: 3,
        text: "国有企业",
    },
    {
        key: 4,
        value: 4,
        text: "中外合资",
    },
    {
        key: 5,
        value: 5,
        text: "自主创业",
    },
    {
        key: 6,
        value: 6,
        text: "事业单位",
    },
    {
        key: 7,
        value: 7,
        text: "政府机关",
    },
    {
        key: 8,
        value: 8,
        text: "港澳台企业",
    },
    {
        key: 9,
        value: 9,
        text: "非营利组织",
    },
];

export const COMPANY_NATURE_OBJ = {
    0: "未分类",
    1: "外商独资",
    2: "民营企业",
    3: "国有企业",
    4: "中外合资",
    5: "自主创业",
    6: "事业单位",
    7: "政府机关",
    8: "港澳台企业",
    9: "非营利组织",
};

// 客户分类
export const CLIENT_TYPE = [
    {
        key: 0,
        value: 0,
        text: "未分类",
    },
    {
        key: 1,
        value: 1,
        text: "A类",
    },
    {
        key: 2,
        value: 2,
        text: "B类",
    },
    {
        key: 3,
        value: 3,
        text: "C类",
    },
];

export const CLIENT_TYPE_OBJ = {
    0: "未分类",
    1: "A类",
    2: "B类",
    3: "C类",
};

// 客户性质
export const CLIENT_NATURE = [
    {
        key: 0,
        value: 0,
        text: "目标客户",
    },
    {
        key: 1,
        value: 1,
        text: "种子客户",
    },
    {
        key: 2,
        value: 2,
        text: "培育客户",
    },
    {
        key: 3,
        value: 3,
        text: "稳定客户",
    },
    {
        key: 4,
        value: 4,
        text: "伙伴客户",
    },
];

export const CLIENT_NATURE_OBJ = {
    0: "目标客户",
    1: "种子客户",
    2: "培育客户",
    3: "稳定客户",
    4: "伙伴客户",
};

// 客户状态
export const CLIENT_STATUS = [
    {
        key: 1,
        value: 1,
        text: "潜在",
    },
    {
        key: 2,
        value: 2,
        text: "跟进",
    },
    {
        key: 3,
        value: 3,
        text: "有项目",
    },
    {
        key: 4,
        value: 4,
        text: "赢单",
    },
    {
        key: -1,
        value: -1,
        text: "输单",
    },
];
export const CLIENT_OBJ = {
    1: "潜在",
    2: "跟进",
    3: "有项目",
    4: "赢单",
    "-1": "输单",
};

// 来源类型
export const SOURCE_TYPE = [
    {
        key: 1,
        value: "自行创建",
    },
    {
        key: 2,
        value: "会议活动",
    },
    {
        key: 3,
        value: "行业协会",
    },
    {
        key: 4,
        value: "政府渠道",
    },
    {
        key: 5,
        value: "商业数据",
    },
    {
        key: 7,
        value: "友人推荐",
    },
    {
        key: 8,
        value: "渠道机构",
    },
    {
        key: 6,
        value: "其他",
    },
];
export const SOURCE_TYPE_OBJ = {
    1: "自行创建",
    2: "会议活动",
    3: "行业协会",
    4: "政府渠道",
    5: "商业数据",
    6: "其他",
    7: "友人推荐",
    8: "渠道机构",
};

// 注册资本类型
export const CAPITALTYPE = [
    {
        key: 1,
        value: 1,
        text: "人民币",
    },
    {
        key: 2,
        value: 2,
        text: "美元",
    },
    {
        key: 3,
        value: 3,
        text: "港元",
    },
    {
        key: 4,
        value: 4,
        text: "欧元",
    },
    {
        key: 5,
        value: 5,
        text: "日元",
    },
    {
        key: 6,
        value: 6,
        text: "韩元",
    },
];

export const CAPITALTYPE_OBJ = {
    人民币: 1,
    美元: 2,
    港元: 3,
    欧元: 4,
    日元: 5,
    韩元: 6,
};

export const CAPITALTYPE_OBJ2 = {
    1: "人民币",
    2: "美元",
    3: "港元",
    4: "欧元",
    5: "日元",
    6: "韩元",
};

// 办公地类型
export const PLACE_TYPE = {
    1: "总部",
    2: "主要",
    3: "次要",
};

// 决策影响
export const KPIMPORTANCE = [
    {
        key: 1,
        value: "绝对级",
    },
    {
        key: 2,
        value: "重要级",
    },
    {
        key: 3,
        value: "中等级",
    },
    {
        key: 4,
        value: "轻微级",
    },
];

export const KPIMPORTANCE2 = [
    {
        key: 0,
        value: "不确定",
    },
    {
        key: 5,
        value: "可自主决策",
    },
    {
        key: 6,
        value: "不可自主决策",
    },
];

export const KPIMPORTANCE_OBJ = {
    0: "不确定",
    1: "绝对级",
    2: "重要级",
    3: "中等级",
    4: "轻微级",
    5: "可自主决策",
    6: "不可自主决策",
};

// KP职位
export const KPPOST = [
    {
        value: "0100",
        label: "公司高层",
        children: [
            {
                value: "0101",
                label: "董事长",
            },
            {
                value: "0102",
                label: "总经理",
            },
            {
                value: "0103",
                label: "分部负责人",
            },
        ],
    },
    {
        value: "0200",
        label: "公司高管",
        children: [
            {
                value: "0208",
                label: "安全总监",
            },
            {
                value: "0209",
                label: "信息总监",
            },
            {
                value: "0202",
                label: "技术总监",
            },
            {
                value: "0201",
                label: "运营总监",
            },
            {
                value: "0203",
                label: "市场总监",
            },
            {
                value: "0204",
                label: "销售总监",
            },
            {
                value: "0205",
                label: "财务总监",
            },
            {
                value: "0206",
                label: "人事总监",
            },
            {
                value: "0207",
                label: "行政总监",
            },
        ],
    },
    {
        value: "0300",
        label: "安全体系",
        children: [
            {
                value: "0301",
                label: "安全主管",
            },
            {
                value: "0302",
                label: "安全副主管",
            },
            {
                value: "0303",
                label: "安全小主管",
            },
            {
                value: "0304",
                label: "安全专员",
            },
        ],
    },
    {
        value: "0400",
        label: "信息体系",
        children: [
            {
                value: "0401",
                label: "信息主管",
            },
            {
                value: "0402",
                label: "信息副主管",
            },
            {
                value: "0403",
                label: "信息小主管",
            },
            {
                value: "0404",
                label: "信息专员",
            },
        ],
    },
    {
        value: "0500",
        label: "运维体系",
        children: [
            {
                value: "0501",
                label: "运维主管",
            },
            {
                value: "0502",
                label: "运维副主管",
            },
            {
                value: "0503",
                label: "运维小主管",
            },
            {
                value: "0504",
                label: "运维专员",
            },
        ],
    },
    {
        value: "0600",
        label: "网络体系",
        children: [
            {
                value: "0601",
                label: "网络主管",
            },
            {
                value: "0602",
                label: "网络副主管",
            },
            {
                value: "0603",
                label: "网络小主管",
            },
            {
                value: "0604",
                label: "网络专员",
            },
        ],
    },
    {
        value: "0700",
        label: "研发体系",
        children: [
            {
                value: "0701",
                label: "研发主管",
            },
            {
                value: "0702",
                label: "研发副主管",
            },
            {
                value: "0703",
                label: "研发小主管",
            },
            {
                value: "0704",
                label: "研发专员",
            },
        ],
    },
    {
        value: "0800",
        label: "测试体系",
        children: [
            {
                value: "0801",
                label: "测试主管",
            },
            {
                value: "0802",
                label: "测试副主管",
            },
            {
                value: "0803",
                label: "测试小主管",
            },
            {
                value: "0804",
                label: "测试专员",
            },
        ],
    },
    {
        value: "0900",
        label: "风控体系",
        children: [
            {
                value: "0901",
                label: "风控主管",
            },
            {
                value: "0902",
                label: "风控副主管",
            },
            {
                value: "0903",
                label: "风控人员",
            },
        ],
    },
    {
        value: "1000",
        label: "公司其他",
        children: [
            {
                value: "1001",
                label: "助理",
            },
            {
                value: "1002",
                label: "法务",
            },
            {
                value: "1003",
                label: "销售",
            },
            {
                value: "1004",
                label: "市场",
            },
            {
                value: "1005",
                label: "人事",
            },
            {
                value: "1006",
                label: "财务",
            },
            {
                value: "1007",
                label: "行政",
            },
            {
                value: "1008",
                label: "其他",
            },
        ],
    },
];
export const KPRELATION = [
    {
        key: 1,
        value: 1,
        text: "刚加微信，还没见面",
    },
    {
        key: 2,
        value: 2,
        text: "初次见面",
    },
    {
        key: 3,
        value: 3,
        text: "有过正式商务沟通",
    },
    {
        key: 4,
        value: 4,
        text: "可以单独约出来吃饭",
    },
    {
        key: 5,
        value: 5,
        text: "有共同兴趣爱好",
    },
    {
        key: 6,
        value: 6,
        text: "帮忙解决过问题或送过礼",
    },
    {
        key: 7,
        value: 7,
        text: "有共同朋友和社会资源",
    },
    {
        key: 8,
        value: 8,
        text: "欠过你较大人情",
    },
    {
        key: 9,
        value: 9,
        text: "老客户，服务很多年，信任基础很深",
    },
    {
        key: 10,
        value: 10,
        text: "在某些事情上可以为你牺牲个人利益",
    },
];

export const KPRELATION_OBJ = {
    1: "刚加微信，还没见面",
    2: "初次见面",
    3: "有过正式商务沟通",
    4: "可以单独约出来吃饭",
    5: "有共同兴趣爱好",
    6: "帮忙解决过问题或送过礼",
    7: "有共同朋友和社会资源",
    8: "欠过你较大人情",
    9: "老客户，服务很多年，信任基础很深",
    10: "在某些事情上可以为你牺牲个人利益",
};

// 销售默认配置
export const SELLERCONFIG = {
    BASE: 6000, // BASE值
    royalty_ratio: 0.1, // 提成系数
    reimbursement_ratio: 0.05, // 报销比例
    year_ratio: 100, // 年度任务系数
    quarter_ratio: [0.2, 0.2, 0.3, 0.3], // 年度任务季度占比
    conversion_rate: 0.2, // 项目转化率
    average_earnings: 150000, // 项目平均盈利（元）
    limit: 3000, // 授信额度（元）
    A: 8,
    B: 10,
    C: 10,
    base_ratio: [0.3, 0.2, 0.2, 0.3], // BASE结构
    client_ratio: [1, 2, 4], // 客户折算系数
    business_ratio: [1, 0.5, 0.25, 0.125], // 项目折算系数
};

// 项目分类 注意'其他'的key
export const BUSINESSTYPE = [
    {
        key: 1,
        value: 1,
        text: "漏洞之眼",
    },
    {
        key: 2,
        value: 2,
        text: "一云",
    },
    {
        key: 3,
        value: 3,
        text: "CADC",
    },
    {
        key: 4,
        value: 4,
        text: "其他产品",
    },
    {
        key: 5,
        value: 5,
        text: "渗透测试",
    },
    {
        key: 6,
        value: 6,
        text: "等级保护",
    },
    {
        key: 7,
        value: 7,
        text: "漏洞扫描",
    },
    {
        key: 9,
        value: 9,
        text: "开发服务",
    },
    {
        key: 8,
        value: 8,
        text: "其他服务",
    },
];

export const BUSINESSTYPE_OBJ = {
    1: "漏洞之眼",
    2: "一云",
    3: "CADC",
    4: "其他产品",
    5: "渗透测试",
    6: "等级保护",
    7: "漏洞扫描",
    9: "开发服务",
    8: "其他服务",
};

// 项目性质
export const BUSINESSNATURE = [
    {
        key: 1,
        value: 1,
        text: "自营",
    },
    {
        key: 2,
        value: 2,
        text: "集成",
    },
    {
        key: 3,
        value: 3,
        text: "代理订单"
    },

    {
        key: 4,
        value: 4,
        text: "框架"
    },
];

export const BUSINESSNATURE_OBJ = {
    1: "自营",
    2: "集成",
    3: "过单"
};

// 项目等级
export const BUSINESSLEVEL = [
    {
        key: 1,
        value: 1,
        text: "A（15万+）",
    },
    {
        key: 2,
        value: 2,
        text: "B（10万-15万）",
    },
    {
        key: 3,
        value: 3,
        text: "C（5万-10万）",
    },
    {
        key: 4,
        value: 4,
        text: "D（0-5万）",
    },
];

export const BUSINESSLEVELOBJ = {
    1: "A（15万+）",
    2: "B（10万-15万）",
    3: "C（5万-10万）",
    4: "D（0-5万）",
};

// 项目进度
export const BUSINESSSCHEDULE = [
    {
        key: 2,
        value: 2,
        text: "需求确定",
    },
    {
        key: 3,
        value: 3,
        text: "方案阶段",
    },
    {
        key: 4,
        value: 4,
        text: "报价预估",
    },
    {
        key: 5,
        value: 5,
        text: "谈判阶段",
    },
    {
        key: 6,
        value: 6,
        text: "赢单",
    },
    {
        key: 7,
        value: 7,
        text: "输单",
    },
    {
        key: 8,
        value: 8,
        text: "商机报备",
    },
    {
        key: 9,
        value: 9,
        text: "毛利核算",
    },
    {
        key: 10,
        value: 10,
        text: "决策层公关",
    },
    {
        key: 11,
        value: 11,
        text: "方案评选",
    },
    {
        key: 12,
        value: 12,
        text: "合同申请",
    },
    {
        key: 13,
        value: 13,
        text: "等待赢单",
    },
];

export const BUSINESSSCHEDULE_OBJ = {
    1: "项目立项",
    2: "需求确定",
    3: "方案阶段",
    4: "报价预估",
    5: "谈判阶段",
    6: "赢单",
    7: "输单",
    8: "项目立项",
    9: "毛利核算",
    10: "决策层公关",
    11: "方案评选",
    12: "合同申请",
    13: "等待赢单",
};

export const BUSINESSSCHEDULE2 = [
    {
        key: 8,
        value: 8,
        text: "项目立项",
    },
    {
        key: 4,
        value: 4,
        text: "成本评估与报价",
    },
    {
        key: 12,
        value: 12,
        text: "合同签署",
    },
    {
        key: 9,
        value: 9,
        text: "毛利核算",
    },
    {
        key: 13,
        value: 13,
        text: "等待赢单",
    },
    {
        key: 6,
        value: 6,
        text: "赢单",
    },
    {
        key: 7,
        value: 7,
        text: "输单",
    },
];

export const BUSINESSSCHEDULE3 = [
    {
        key: 2,
        value: 2,
        text: "需求确定",
    },
    {
        key: 3,
        value: 3,
        text: "方案阶段",
    },
    {
        key: 4,
        value: 4,
        text: "报价预估",
    },
    {
        key: 5,
        value: 5,
        text: "谈判阶段",
    },
    {
        key: 6,
        value: 6,
        text: "赢单",
    },
    {
        key: 7,
        value: 7,
        text: "输单",
    },
];

export const CLOCKSTAGE_TO_BUSINESSSCHEDULE = {
    4: 8,
    5: 9,
    6: 10,
    7: 11,
    8: 12,
    9: 13,
};

// 输单原因
// export const BUSINESSQUESTIONFIRST = [
//   {
//     key: 1,
//     value: 1,
//     text: "客户问题",
//   },
//   {
//     key: 2,
//     value: 2,
//     text: "公司问题",
//   },
//   {
//     key: 3,
//     value: 3,
//     text: "商务问题",
//   },
// ];
export const BUSINESSQUESTION = [
    {
        key: 1,
        value: 1,
        label: "客户问题",
        children: [
            {
                key: 101,
                value: 101,
                label: "需求与意向不足",
            },
            {
                key: 102,
                value: 102,
                label: "需求变化",
            },
            {
                key: 103,
                value: 103,
                label: "需求难以交付",
            },
            {
                key: 104,
                value: 104,
                label: "对接人岗位变更",
            },
            {
                key: 105,
                value: 105,
                label: "供应商已满",
            },
            {
                key: 106,
                value: 106,
                label: "预算障碍",
            },
            {
                key: 107,
                value: 107,
                label: "高层干预",
            },
            {
                key: 108,
                value: 108,
                label: "采购干预",
            },
            {
                key: 109,
                value: 109,
                label: "地域问题",
            },
            {
                key: 110,
                value: 110,
                label: "其他",
            },
        ],
    },
    {
        key: 2,
        value: 2,
        label: "公司问题",
        children: [
            {
                key: 201,
                value: 201,
                label: "公司不符合准入条件",
            },
            {
                key: 202,
                value: 202,
                label: "产品服务不满足需求",
            },
            {
                key: 203,
                value: 203,
                label: "监管单位干预",
            },
            {
                key: 204,
                value: 204,
                label: "口碑问题",
            },
            {
                key: 205,
                value: 205,
                label: "解决方案未受认可",
            },
            {
                key: 206,
                value: 206,
                label: "POC未受认可",
            },
            {
                key: 207,
                value: 207,
                label: "缺乏性价比",
            },
            {
                key: 208,
                value: 208,
                label: "投标工作失误",
            },
            {
                key: 209,
                value: 209,
                label: "客户交付不满意",
            },
            {
                key: 211,
                value: 211,
                label: "转岗/离职",
            },
            {
                key: 210,
                value: 210,
                label: "其他",
            },
        ],
    },
    {
        key: 3,
        value: 3,
        label: "商务问题",
        children: [
            {
                key: 301,
                value: 301,
                label: "未接洽到对接人",
            },
            {
                key: 302,
                value: 302,
                label: "招投标失控",
            },
            {
                key: 303,
                value: 303,
                label: "竞争对手恶意竞争",
            },
            {
                key: 304,
                value: 304,
                label: "关系深度不够",
            },
            {
                key: 305,
                value: 305,
                label: "商务工作失误",
            },
            {
                key: 306,
                value: 306,
                label: "渠道失误",
            },
            {
                key: 307,
                value: 307,
                label: "报价失误",
            },
            {
                key: 308,
                value: 308,
                label: "谈判失误",
            },
            {
                key: 309,
                value: 309,
                label: "合同发票问题",
            },
            {
                key: 310,
                value: 310,
                label: "其他",
            },
        ],
    },
]
// 输单原因
export const BUSINESSQUESTION_OBJ = {
    1: "客户问题",
    101: "需求与意向不足",
    102: "需求变化",
    103: "需求难以交付",
    104: "对接人岗位变更",
    105: "供应商已满",
    106: "预算障碍",
    107: "高层干预",
    108: "采购干预",
    109: "地域问题",
    110: "其他",
    2: "公司问题",
    201: "公司不符合准入条件",
    202: "产品服务不满足需求",
    203: "监管单位干预",
    204: "口碑问题",
    205: "解决方案未受认可",
    206: "POC未受认可",
    207: "缺乏性价比",
    208: "投标工作失误",
    209: "客户交付不满意",
    211: "转岗/离职",
    210: "其他",
    3: "商务问题",
    301: "未接洽到对接人",
    302: "招投标失控",
    303: "竞争对手恶意竞争",
    304: "关系深度不够",
    305: "商务工作失误",
    306: "渠道失误",
    307: "报价失误",
    308: "谈判失误",
    309: "合同发票问题",
    310: "其他",
};

// 打卡对象
export const CLOCKTYPE = [
    {
        key: 1,
        value: 1,
        text: "客户",
    },
    {
        key: 1.1,
        value: 1.1,
        text: "陌拜",
    },
    {
        key: 1.5,
        value: 1.5,
        text: "特殊",
    },
    {
        key: 2,
        value: 2,
        text: "会议",
    },
    {
        key: 3,
        value: 3,
        text: "办公室",
    },
    {
        key: 4,
        value: 4,
        text: "在家",
    },
    {
        key: 5,
        value: 5,
        text: "其他",
    },
    {
        key: 6,
        value: 6,
        text: "出门",
    },
    {
        key: 7,
        value: 7,
        text: "外出",
    },
    {
        key: 8,
        value: 8,
        text: "随访",
    },
    {
        key: 9,
        value: 9,
        text: "下班",
    },
];
export const CLOCKTYPE2 = [
    {
        key: 1,
        value: 1,
        text: "客户",
    },
    {
        key: 6,
        value: 6,
        text: "出门",
    },
    {
        key: 1.5,
        value: 1.5,
        text: "特殊",
    },
    {
        key: 3,
        value: 3,
        text: "办公室",
    },
    {
        key: 7,
        value: 7,
        text: "外出",
    },
    {
        key: 8,
        value: 8,
        text: "随访",
    },
    {
        key: 9,
        value: 9,
        text: "下班",
    },
];
export const CLOCKTYPE3 = [
    {
        key: 1,
        value: 1,
        text: "客户",
    },
    {
        key: 1.5,
        value: 1.5,
        text: "特殊",
    },
    {
        key: 3,
        value: 3,
        text: "办公室",
    },
    {
        key: 7,
        value: 7,
        text: "外出",
    },
    {
        key: 8,
        value: 8,
        text: "随访",
    },
];
export const CLOCKTYPEOBJ_OBJ = {
    1: {
        key: 1,
        value: 1,
        text: "客户公司拜访",
    },
    6: {
        key: 6,
        value: 6,
        text: "早上出门",
    },
    1.5: {
        key: 1.5,
        value: 1.5,
        text: "客户招待",
    },
    3: {
        key: 3,
        value: 3,
        text: "公司办公",
    },
    7: {
        key: 7,
        value: 7,
        text: "外出",
    },
    8: {
        key: 8,
        value: 8,
        text: "客户随访",
    },
    9: {
        key: 9,
        value: 9,
        text: "下班",
    },
};
export const CLOCKTYPEOBJ = {
    1: "客户公司拜访",
    1.1: "陌拜",
    1.5: "客户招待",
    2: "特殊会议",
    3: "公司办公",
    4: "在家",
    5: "其他",
    6: "早上出门",
    7: "外出",
    8: "客户随访",
    9: "下班",
};

// 成单期望
export const PROBABILITY = [
    {
        key: 1,
        value: 1,
        text: "确定级",
        number: "100%",
    },
    {
        key: 2,
        value: 2,
        text: "较大级",
        number: "80%",
    },
    {
        key: 3,
        value: 3,
        text: "乐观级",
        number: "60%",
    },
    {
        key: 4,
        value: 4,
        text: "观察级",
        number: "50%",
    },
    {
        key: 5,
        value: 5,
        text: "努力级",
        number: "40%",
    },
    {
        key: 6,
        value: 6,
        text: "渺茫级",
        number: "20%",
    },
];

//拜访阶段
export const VISITSTATE = [
    {
        key: 1,
        value: 1,
        text: "目标客户",
        number: "10%",
    },
    {
        key: 2,
        value: 2,
        text: "KP录入",
        number: "30%",
    },
    {
        key: 3.3,
        value: 3.3,
        text: "商机报备",
        number: "30%",
    },
    {
        key: 4.1,
        value: 4.1,
        text: "成本评估与报价",
        number: "50%",
    },
    {
        key: 4,
        value: 4,
        text: "项目立项",
        number: "40%",
    },
    {
        key: 8,
        value: 8,
        text: "合同签署",
        number: "80%",
    },
    {
        key: 5,
        value: 5,
        text: "毛利核算",
        number: "50%",
    },
    {
        key: 9,
        value: 9,
        text: "等待赢单",
        number: "100%",
    },
];

export const VISITSTATEOBJ = {
    1: "目标客户",
    2: "KP录入",
    3.3: "商机报备", //"KP邀约",
    3.1: "冷线索",
    3.2: "热线索",
    4: "项目立项",
    4.1: "成本评估与报价",
    5: "毛利核算",
    6: "决策层公关",
    7: "方案评选",
    8: "合同签署",
    9: "等待赢单",
    10: "赢单",
    11: "输单",
};
//特殊卡类型
export const SPECIALTYPE = [
    {
        key: 1,
        value: 1,
        text: "会议",
    },
    {
        key: 3,
        value: 3,
        text: "客户",
    },
];

export const SPECIALTYPEOBJ = {
    1: "会议",
    2: "渠道",
    3: "客户",
};

// 项目提成系数
export const COMMISSIONRATE = 0.1;

// 报销额度
export const EXPENSE = 0.05;

// 销售行为考核分数
export const SELLERBEHAVIORSCORE = [
    {
        key: 7,
        value: {
            content: "例会无故缺席",
            score: -10,
        },
        text: "例会无故缺席",
    },
    {
        key: 8,
        value: {
            content: "培训无故缺席",
            score: -20,
        },
        text: "培训无故缺席",
    },
    {
        key: 9,
        value: {
            content: "事假超额",
            score: -3,
        },
        text: "事假超额",
    },
    {
        key: 10,
        value: {
            content: "CRM虚假数据",
            score: -50,
        },
        text: "CRM虚假数据",
    },
    {
        key: -1,
        value: {
            content: "",
            score: null,
        },
        text: "其它",
    },
];
// 小队行为考核分数
export const TEAMBEHAVIORSCORE = [
    {
        key: 11,
        value: {
            type: 11,
            score: -20,
        },
        text: "队长管理失职 队长-20",
    },
    {
        key: 12,
        value: {
            type: 12,
            score: -10,
        },
        text: "队长未召开例会 全体-10",
    },
];
export const BEHAVIOROBJ = {
    7: {
        score: -10,
        content: "例会无故缺席",
    },
    8: {
        score: -20,
        content: "培训无故缺席",
    },
    9: {
        score: -3,
        content: "事假超额",
    },
    10: {
        score: -50,
        content: "CRM虚假数据",
    },
    11: {
        score: -20,
        content: "队长管理失职",
    },
    12: {
        score: -10,
        content: "队长未召开例会",
    },
};

// 资金量级
export const CAPITALLEVEL = [
    {
        value: 1,
        text: "A（1000万+）",
    },
    {
        value: 2,
        text: "B（100万-1000万）",
    },
    {
        value: 3,
        text: "C（0-100万）",
    },
];
// 资金量级简化版
export const CAPITALLEVELSIMPLE = [
    {
        value: 1,
        text: "A",
    },
    {
        value: 2,
        text: "B",
    },
    {
        value: 3,
        text: "C",
    },
];

// 资金类别
export const CAPITALCLASS = [
    {
        value: 1,
        text: "研发类",
    },
    {
        value: 2,
        text: "人才类",
    },
    {
        value: 3,
        text: "公司类",
    },
];

// 资金里程碑
export const CAPITALMILESTONE = [
    {
        value: 1,
        text: "待评估",
    },
    {
        value: 2,
        text: "已评估",
    },
    {
        value: 3,
        text: "已申报",
    },
    {
        value: 4,
        text: "已评审",
    },
    {
        value: 5,
        text: "已立项",
    },
    {
        value: 6,
        text: "已建设",
    },
    {
        value: 7,
        text: "已验收",
    },
    {
        value: 8,
        text: "已结项",
    },
    {
        value: 9,
        text: "已总结",
    },
    {
        value: -1,
        text: "已流失",
    },
];

// 资金所处阶段
export const CAPITALSTATE = [
    {
        value: 1,
        text: "评估阶段",
    },
    {
        value: 2,
        text: "申报阶段",
    },
    {
        value: 3,
        text: "评审阶段",
    },
    {
        value: 4,
        text: "立项阶段",
    },
    {
        value: 5,
        text: "建设阶段",
    },
    {
        value: 6,
        text: "验收阶段",
    },
    {
        value: 7,
        text: "结项阶段",
    },
    {
        value: 8,
        text: "总结阶段",
    },
    {
        value: 9,
        text: "完成阶段",
    },
];

// 资金申报单位
export const DECLAREUNIT = [
    {
        value: "上海谋乐网络科技有限公司",
        text: "上海谋乐网络科技有限公司",
    },
    {
        value: "上海诚墙网络信息有限公司",
        text: "上海诚墙网络信息有限公司",
    },
];

// 代理机构费用等级
export const AGENCYCHARGE = [
    {
        key: 1,
        value: 1,
        text: "高",
    },
    {
        key: 2,
        value: 2,
        text: "中",
    },
    {
        key: 3,
        value: 3,
        text: "低",
    },
];

// 线索状态
export const THREADSTATUS = [
    {
        key: 1,
        value: 1,
        text: "待评估",
    },
    {
        key: 2,
        value: 2,
        text: "无效",
    },
    {
        key: 3,
        value: 3,
        text: "有效",
    },
    {
        key: 4,
        value: 4,
        text: "立项",
    },
    {
        key: 5,
        value: 5,
        text: "放弃",
    },
];

// 手机归属
export const MOBILE_OWNER = [
    {
        key: 1,
        value: 1,
        text: "私人",
    },
    {
        key: 2,
        value: 2,
        text: "公司",
    },
];

// 平台类别
export const ACCOUNT_PLATFORM_TYPE = [
    {
        key: 1,
        value: 1,
        text: "自媒体平台",
    },
    {
        key: 2,
        value: 2,
        text: "运营账号",
    },
    {
        key: 3,
        value: 3,
        text: "公司账号",
    },
    {
        key: 4,
        value: 4,
        text: "供应商账号",
    },
    {
        key: -1,
        value: -1,
        text: "其它",
    },
];

// 会议类别
export const MEETING_TYPE = [
    {
        key: 1,
        value: 1,
        text: "大会",
    },
    {
        key: 2,
        value: 2,
        text: "沙龙",
    },
    {
        key: 3,
        value: 3,
        text: "聚会",
    },
    {
        key: 4,
        value: 4,
        text: "年会",
    },
];

// 会议方式
export const MEETING_WAY = [
    {
        key: 1,
        value: 1,
        text: "线上",
    },
    {
        key: 2,
        value: 2,
        text: "线下",
    },
];

// 会议行为
export const MEETING_ACTION = [
    {
        key: 1,
        value: 1,
        text: "参会",
    },
    {
        key: 2,
        value: 2,
        text: "主讲",
    },
    {
        key: 3,
        value: 3,
        text: "布展",
    },
    {
        key: 4,
        value: 4,
        text: "圆桌",
    },
    {
        key: 5,
        value: 5,
        text: "主办",
    },
    {
        key: 6,
        value: 6,
        text: "协办",
    },
    {
        key: 7,
        value: 7,
        text: "赞助",
    },
];

// 参会人群
export const MEETING_GROUP = [
    {
        key: 1,
        value: 1,
        text: "甲方活动",
    },
    {
        key: 2,
        value: 2,
        text: "乙方活动",
    },
    {
        key: -1,
        value: -1,
        text: "其他",
    },
];

// 会议质量
export const MEETING_QUALITY = [
    {
        key: 1,
        value: 1,
        text: "A",
    },
    {
        key: 2,
        value: 2,
        text: "B",
    },
    {
        key: 3,
        value: 3,
        text: "C",
    },
    {
        key: 4,
        value: 4,
        text: "D",
    },
];

// 奖项质量
export const AWARDQUALITY = [
    {
        key: 1,
        value: 1,
        text: "高",
    },
    {
        key: 2,
        value: 2,
        text: "中",
    },
    {
        key: 3,
        value: 3,
        text: "低",
    },
];

// 奖项性质
export const AWARDTYPE = [
    {
        key: 1,
        value: 1,
        text: "奖项",
    },
    {
        key: 2,
        value: 2,
        text: "榜单",
    },
    {
        key: 3,
        value: 3,
        text: "证明",
    },
];

// 奖项面向对象
export const AWARDTARGET = [
    {
        key: 1,
        value: 1,
        text: "个人",
    },
    {
        key: 2,
        value: 2,
        text: "企业（商业）",
    },
    {
        key: 3,
        value: 3,
        text: "技术",
    },
    {
        key: 4,
        value: 4,
        text: "服务",
    },
    {
        key: 5,
        value: 5,
        text: "产品",
    },
];

// 奖项平台质量
export const PLATFORMQUALITY = [
    {
        key: 1,
        value: 1,
        text: "高",
    },
    {
        key: 2,
        value: 2,
        text: "中",
    },
    {
        key: 3,
        value: 3,
        text: "低",
    },
];

// 奖项里程碑
export const AWARDMILESTONE = [
    {
        value: 1,
        text: "待评估",
    },
    {
        value: 2,
        text: "已评估",
    },
    {
        value: 3,
        text: "已申报",
    },
    {
        value: 4,
        text: "已评审",
    },
    {
        value: 5,
        text: "已结项",
    },
    {
        value: 6,
        text: "已总结",
    },
    {
        value: -1,
        text: "已流失",
    },
];

// 奖项所处阶段
export const AWARDSTATE = [
    {
        value: 1,
        text: "评估阶段",
    },
    {
        value: 2,
        text: "申报阶段",
    },
    {
        value: 3,
        text: "评审阶段",
    },
    {
        value: 4,
        text: "结项阶段",
    },
    {
        value: 5,
        text: "总结阶段",
    },
    {
        value: 6,
        text: "完成阶段",
    },
];

// 知识库模块类型
export const MODULE_TYPE = [
    {
        key: 1,
        value: 1,
        text: "公开知识库",
    },
    {
        key: 2,
        value: 2,
        text: "部门知识库",
    },
    {
        key: 3,
        value: 3,
        text: "私人知识库",
    },
];

// 税点类型
export const TAX_TYPE2 = [
    {
        key: 4,
        value: 0,
        text: "0%(普票)",
    },
    {
        key: 5,
        value: 1,
        text: "1%",
    },
    {
        key: 6,
        value: 3,
        text: "3%",
    },
    {
        key: 1,
        value: 6,
        text: "6%",
    },
    {
        key: 2,
        value: 13,
        text: "13%",
    },
    {
        key: 3,
        value: -1,
        text: "其他",
    }
];

// 税点类型
export const TAX_TYPE = [
    {
        key: 1,
        value: 6,
        text: "6%",
    },
    {
        key: 2,
        value: 13,
        text: "13%",
    },
    {
        key: 3,
        value: -1,
        text: "其他",
    },
];

// 任务紧急度
export const TASK_IMPORTANT = [
    {
        key: 0,
        value: 0,
        text: "高",
    },
    {
        key: 1,
        value: 1,
        text: "中",
    },
    {
        key: 2,
        value: 2,
        text: "低",
    },
];

// 合同类型
export const CONTRACT_HEAD_TYPE = [
    {
        key: 1,
        value: 1,
        text: "销售合同",
    },
    {
        key: 2,
        value: 2,
        text: "采购合同",
    },
];
export const CONTRACT_HEAD_TYPE_MOA = [
    {
        key: 1,
        value: 1,
        text: "销售合同",
    },
    /* {
        key: 8,
        value: 8,
        text: "业务采购合同",
    }, */
    {
        key: 2,
        value: 2,
        text: "采购合同",
    },
    {
        key: 3,
        value: 3,
        text: "人事合同",
    },
    {
        key: 4,
        value: 4,
        text: "项目合同",
    },
    {
        key: 5,
        value: 5,
        text: "政府合同",
    },
    {
        key: 6,
        value: 6,
        text: "二类销售合同",
    },
    {
        key: 7,
        value: 7,
        text: "二类采购合同",
    },
    {
        key: 9,
        value: 9,
        text: "内部合同",
    },
    {
        key: 10,
        value: 10,
        text: "保密协议",
    },
    {
        key: 11,
        value: 11,
        text: "授权委托书",
    },
    {
        key: -1,
        value: -1,
        text: "其他合同",
    },
];
export const CONTRACT_HEAD_TYPE_ALL = [
    {
        key: 1,
        value: 1,
        text: "销售合同",
    },
    /* {
        key: 8,
        value: 8,
        text: "业务采购合同",
    }, */
    {
        key: 2,
        value: 2,
        text: "采购合同",
    },
    {
        key: 3,
        value: 3,
        text: "人事合同",
    },
    {
        key: 4,
        value: 4,
        text: "项目合同",
    },
    {
        key: 5,
        value: 5,
        text: "政府合同",
    },
    {
        key: 6,
        value: 6,
        text: "二类销售合同",
    },
    {
        key: 7,
        value: 7,
        text: "二类采购合同",
    },
    {
        key: 9,
        value: 9,
        text: "内部合同",
    },
    {
        key: 10,
        value: 10,
        text: "保密协议",
    },
    {
        key: 11,
        value: 11,
        text: "授权委托书",
    },
    {
        key: -1,
        value: -1,
        text: "其他合同",
    },
];
export const CONTRACT_HEAD_TYPE_ALL_OBJ = {
    1: "销售合同",
    2: "采购合同",
    3: "人事合同",
    4: "项目合同",
    5: "政府合同",
    "-1": "其他合同",
    6: "二类销售合同",
    7: "二类采购合同",
    8: "业务采购合同",
    9: "内部合同",
    10: "保密协议",
    11: "授权委托书",
};

// 合同性质,新增合同性质记得改相关代码
export const CONTRACT_SUB_TYPE = [
    {
        key: 3,
        value: 3,
        text: "普通合同",
    },
    {
        key: 2,
        value: 2,
        text: "订单合同",
    },
    {
        key: 1,
        value: 1,
        text: "框架合同",
    },
    {
        key: 4,
        value: 4,
        text: "项目合同",
    },
];

export const CONTRACT_SUB_TYPE_OBJ = {
    1: {
        key: 1,
        value: 1,
        text: "框架合同",
    },
    2: {
        key: 2,
        value: 2,
        text: "订单合同",
    },
    3: {
        key: 3,
        value: 3,
        text: "普通合同",
    },
    4: {
        key: 4,
        value: 4,
        text: "项目合同",
    },
};

export const CONTRACT_STAGE = [
    //合同阶段
    {
        key: 1,
        value: 1,
        text: "申请提交",
    },
    {
        key: 2,
        value: 2,
        text: "合同内容审批",
    },
    {
        key: 3,
        value: 3,
        text: "合同交付部门审批",
    },
    {
        key: 4,
        value: 4,
        text: "合同法务审批",
    },
    {
        key: 5,
        value: 5,
        text: "合同下载",
    },
    {
        key: 6,
        value: 6,
        text: "合同上传",
    },
    {
        key: 7,
        value: 7,
        text: "合同最终确认",
    },
];

// 项目经理支撑等级
export const SUPPORT_TYPE = [
    {
        key: 2,
        value: 2,
        text: "招投标支撑",
    },
    {
        key: 3,
        value: 3,
        text: "标准支撑",
    },
    {
        key: 1,
        value: 1,
        text: "顾问支撑",
    },
];
export const SUPPORT_TYPE_OBJ = {
    1: "顾问支撑",
    2: "招投标支撑",
    3: "标准支撑",
};

// 推荐级别
export const RECOMMEND_TYPE = [
    {
        key: 1,
        value: 1,
        text: "介绍型",
    },
    {
        key: 2,
        value: 2,
        text: "全控型",
    },
];
export const RECOMMEND_TYPE_OBJ = {
    1: "介绍型",
    2: "全控型",
};

export var SELLERDEPARTMENT = [
    {
        key: 2,
        value: 2,
        text: "分子公司商务",
    },
    {
        key: 4,
        value: 4,
        text: "MA01 老A团队",
    },
    {
        key: 16,
        value: 16,
        text: "项目经理团队"
    },
    {
        key: 17,
        value: 17,
        text: "KA01 商务团队"
    },
    {
        key: 18,
        value: 18,
        text: "KA00 商务团队"
    },
    {
        key: 19,
        value: 19,
        text: "KA02 商务团队"
    },
    {
        key: 20,
        value: 20,
        text: "KA03 商务团队"
    },
    {
        key: 21,
        value: 21,
        text: "KA04 商务团队"
    },
    {
        key: -1,
        value: -1,
        text: "其他",
    },
];

export const SELLERDEPARTMENT_OBJ = {
    1: "孙强团队",
    2: "分子公司商务",
    3: "张琦团队",
    4: "老A团队",
    5: "陈恺团队",
    6: "荣学之团队",
    7: "周杨团队",
    8: "李毅团队",
    9: "康芦团队",
    10: "陈文星团队",
    11: "张怡团队",
    12: "赵一飞团队",
    13: "吴思宏团队",
    14: "商务一部",
    15: "吴昕团队",
    16: "项目经理团队",
    17: "KA01 商务团队",
    18: "KA00 商务团队",
    19: "KA02 商务团队",
    20: "KA03 商务团队",
    21: "KA04 商务团队",
    30: "A组-申露萍",
    31: "B组-许丽莎",
    32: "C组-牟可欣",
    35: "新手村",
    36: "BD团队",
    "-1": "其他",
};

export const SELLERDEPARTMENTNAME = [
    {
        key: 1,
        value: 1,
        text: "孙强",
    },
    {
        key: 2,
        value: 2,
        text: "分子公司商务",
    },
    {
        key: 3,
        value: 3,
        text: "张琦",
    },
    {
        key: 4,
        value: 4,
        text: "MA01 老A",
    },
    {
        key: 5,
        value: 5,
        text: "陈恺",
    },
    {
        key: 6,
        value: 6,
        text: "荣学之",
    },
    {
        key: 7,
        value: 7,
        text: "周杨",
    },
    {
        key: 8,
        value: 8,
        text: "李毅",
    },
    {
        key: 9,
        value: 9,
        text: "康芦",
    },
    {
        key: 10,
        value: 10,
        text: "陈文星",
    },
    {
        key: 11,
        value: 11,
        text: "张怡",
    },
    {
        key: 12,
        value: 12,
        text: "赵一飞"
    },
    {
        key: 13,
        value: 13,
        text: "吴思宏"
    },
    {
        key: 14,
        value: 14,
        text: "汪晨"
    },
    {
        key: 15,
        value: 15,
        text: "吴昕"
    },
    {
        key: 16,
        value: 16,
        text: "项目经理"
    },
    {
        key: 17,
        value: 17,
        text: "KA01 商务团队"
    },
    {
        key: 18,
        value: 18,
        text: "KA00 商务团队"
    },
    {
        key: 19,
        value: 19,
        text: "KA02 商务团队"
    },
    {
        key: 20,
        value: 20,
        text: "KA03 商务团队"
    },
    {
        key: 21,
        value: 21,
        text: "KA04 商务团队"
    },
    {
        key: -1,
        value: -1,
        text: "其他",
    },
];
export const SELLERDEPARTMENT2 = [
    /* {
      key: 1,
      value: 1,
      text: "孙强团队",
    }, */
    {
        key: 2,
        value: 2,
        text: "分子公司商务",
    },
    /* {
      key: 3,
      value: 3,
      text: "张琦团队",
    }, */
    {
        key: 4,
        value: 4,
        text: "MA01 老A团队",
    },
    /* {
      key: 5,
      value: 5,
      text: "陈恺团队",
    },
    {
      key: 6,
      value: 6,
      text: "荣学之团队",
    },
    {
      key: 7,
      value: 7,
      text: "周杨团队",
    },
    {
      key: 8,
      value: 8,
      text: "李毅团队",
    },
    {
      key: 9,
      value: 9,
      text: "康芦团队",
    },
    {
      key: 10,
      value: 10,
      text: "陈文星团队",
    },
    {
      key: 11,
      value: 11,
      text: "张怡团队",
    },
    {
      key: 12,
      value: 12,
      text: "赵一飞团队"
    },
    {
      key: 13,
      value: 13,
      text: "吴思宏团队"
    },
    {
      key: 14,
      value: 14,
      text: "商务一部"
    },
    {
      key: 15,
      value: 15,
      text: "吴昕团队"
    },
    {
      key: 16,
      value: 16,
      text: "项目经理团队"
    }, */
    {
        key: 17,
        value: 17,
        text: "KA01 商务团队"
    },
    {
        key: 18,
        value: 18,
        text: "KA00 商务团队"
    },
    {
        key: 19,
        value: 19,
        text: "KA02 商务团队"
    },
    {
        key: 20,
        value: 20,
        text: "KA03 商务团队"
    },
    {
        key: 21,
        value: 21,
        text: "KA04 商务团队"
    },
];

export const OFFICE_ADDRESS = [
    {
        longitude: 121.211675,
        latitude: 31.047168,
        text: "谋乐网络科技(松江)",
        radius: 30,
    },
    {
        longitude: 121.360324,
        latitude: 31.124406,
        text: "谋乐网络科技有限公司(大虹桥)",
        radius: 100,
    },
    {
        longitude: 121.39114396565938,
        latitude: 31.196645190094845,
        text: "虹桥小别墅",
        radius: 100,
    },
    {
        longitude: 121.3849972114129,
        latitude: 31.228398556091218,
        text: "普陀大数据中心",
        radius: 30,
    },
    {
        longitude: 121.37268093532987,
        latitude: 31.264744737413196,
        text: "上海莱克汽车维修有限公司",
        radius: 30,
    },
    {
        longitude: 121.390977,
        latitude: 31.249977,
        text: "普陀保安公司",
        radius: 30,
    },
];

//认可度
export const APPROVAL_TYPE = [
    {
        key: 1,
        value: 1,
        text: "非常认可",
    },
    {
        key: 2,
        value: 2,
        text: "认可",
    },
    {
        key: 3,
        value: 3,
        text: "一般",
    },
    {
        key: 4,
        value: 4,
        text: "不认可",
    },
];

export const APPROVAL_TYPE_OBJ = {
    "-1": "无",
    1: "非常认可",
    2: "认可",
    3: "一般",
    4: "不认可",
};

//竞争优势
export const OPPONENT_TYPE = [
    {
        key: 1,
        value: 1,
        text: "绝对优势",
    },
    {
        key: 2,
        value: 2,
        text: "略有优势",
    },
    {
        key: 3,
        value: 3,
        text: "普通",
    },
    {
        key: 4,
        value: 4,
        text: "略有劣势",
    },
    {
        key: 5,
        value: 5,
        text: "绝对劣势",
    },
];

export const OPPONENT_TYPE_OBJ = {
    1: "绝对优势",
    2: "略有优势",
    3: "普通",
    4: "略有劣势",
    5: "绝对劣势",
};

//策略选择
export const OPPONENT_SELECT_TYPE = [
    {
        key: 1,
        value: 1,
        text: "价格优势",
    },
    {
        key: 2,
        value: 2,
        text: "技术优势",
    },
    {
        key: 3,
        value: 3,
        text: "关系优势",
    },
    {
        key: 4,
        value: 4,
        text: "品牌优势",
    },
];

export const OPPONENT_SELECT_TYPE_OBJ = {
    1: "价格优势",
    2: "技术优势",
    3: "关系优势",
    4: "品牌优势",
};

export const IMPORTANCE = [
    {
        key: 1,
        value: 1,
        text: "核心",
    },
    {
        key: 2,
        value: 2,
        text: "重要",
    },
    {
        key: 3,
        value: 3,
        text: "一般",
    },
    {
        key: 4,
        value: 4,
        text: "较低",
    },
];

export const IMPORTANCE_OBJ = {
    1: "核心",
    2: "重要",
    3: "一般",
    4: "较低",
};

export const TEAM = ["安全服务部", "产品二部", "运营部"];

export const WEAPONATTRIBUTE = [
    {
        key: 1,
        value: 1,
        text: "产品",
    },
    {
        key: 2,
        value: 2,
        text: "服务",
    },
    {
        key: 3,
        value: 3,
        text: "产品和服务",
    },
];

export const WEAPONATTRIBUTE_OBJ = {
    1: "产品",
    2: "服务",
    3: "产品和服务",
};

//项目经理调派人天对应
export const OCCUPY = [
    {
        key: 1,
        value: 1,
        text: "外派拜访",
        number: 0.5,
    },
    {
        key: 2,
        value: 2,
        text: "普通标书",
        number: 0.8,
    },
    {
        key: 3,
        value: 3,
        text: "大型标书",
        number: 1.5,
    },
    {
        key: 4,
        value: 4,
        text: "基础材料",
        number: 0.1,
    },
    {
        key: 5,
        value: 5,
        text: "普通方案",
        number: 0.8,
    },
    {
        key: 6,
        value: 6,
        text: "大型方案",
        number: 1.5,
    },
    {
        key: 7,
        value: 7,
        text: "电话会议",
        number: 0.2,
    },
    //支撑项目  9其他  1项目经理陪访 2可研报告/方案/标书 3制作新产品PPT /白皮书/产品使用手册  4安全日策划类活动项目管理  5驻场类（驻场咨询、驻场管理项目）6承接A类项目经理 7承接B类项目经理 8承接C类项目经理
    {
        key: 8,
        value: 8,
        text: "【项目承揽】客户项目经理陪访",
        number: 1,
    },
    {
        key: 9,
        value: 9,
        text: "【项目承揽】可研报告/方案/标书",
        number: 2.5,
    },
    {
        key: 10,
        value: 10,
        text: "【项目承揽】制作新产品PPT/白皮书/产品使用手册",
        number: 3,
    },
    {
        key: 16,
        value: 16,
        text: "【项目承揽】其他",
        number: 1,
    },
    {
        key: 11,
        value: 11,
        text: "【项目承做】安全日策划类活动项目管理",
        number: 10,
    },
    {
        key: 12,
        value: 12,
        text: "【项目承做】驻场类（驻场咨询、驻场管理项目）",
        number: 2,
    },
    {
        key: 13,
        value: 13,
        text: "【项目承做】承接A类项目经理",
        number: 15,
    },
    {
        key: 14,
        value: 14,
        text: "【项目承做】承接B类项目经理",
        number: 10,
    },
    {
        key: 15,
        value: 15,
        text: "【项目承做】承接C类项目经理",
        number: 5,
    },
];

export const OCCUPY_NEW1 = [
    {
        key: 8,
        value: 8,
        text: "客户项目经理陪访",
        number: 1,
    },
    {
        key: 9,
        value: 9,
        text: "可研报告/方案/标书",
        number: 2.5,
    },
    {
        key: 10,
        value: 10,
        text: "制作新产品PPT/白皮书/产品使用手册",
        number: 3,
    },
    {
        key: 16,
        value: 16,
        text: "其他",
        number: 1,
    },
]

export const OCCUPY_NEW2 = [
    {
        key: 11,
        value: 11,
        text: "安全日策划类活动项目管理",
        number: 10,
    },
    {
        key: 12,
        value: 12,
        text: "驻场类（驻场咨询、驻场管理项目）",
        number: 2,
    },
    {
        key: 13,
        value: 13,
        text: "承接A类项目经理",
        number: 15,
    },
    {
        key: 14,
        value: 14,
        text: "承接B类项目经理",
        number: 10,
    },
    {
        key: 15,
        value: 15,
        text: "承接C类项目经理",
        number: 5,
    },
]

export const PROJECT_TYPE = {
    1: "项目承揽",
    2: "项目承做",
};

export const OCCUPY_OBJ = {
    1: {
        key: 1,
        value: 1,
        text: "外派拜访",
        number: 0.5,
    },
    2: {
        key: 2,
        value: 2,
        text: "普通标书",
        number: 0.8,
    },
    3: {
        key: 3,
        value: 3,
        text: "大型标书",
        number: 1.5,
    },
    4: {
        key: 4,
        value: 4,
        text: "基础材料",
        number: 0.1,
    },
    5: {
        key: 5,
        value: 5,
        text: "普通方案",
        number: 0.8,
    },
    6: {
        key: 6,
        value: 6,
        text: "大型方案",
        number: 1.5,
    },
    7: {
        key: 7,
        value: 7,
        text: "电话会议",
        number: 0.2,
    },
    8: {
        key: 8,
        value: 8,
        text: "客户项目经理陪访",
        number: 1,
    },
    9: {
        key: 9,
        value: 9,
        text: "可研报告/方案/标书",
        number: 2.5,
    },
    10: {
        key: 10,
        value: 10,
        text: "制作新产品PPT/白皮书/产品使用手册",
        number: 3,
    },
    11: {
        key: 11,
        value: 11,
        text: "安全日策划类活动项目管理",
        number: 10,
    },
    12: {
        key: 12,
        value: 12,
        text: "驻场类（驻场咨询、驻场管理项目）",
        number: 2,
    },
    13: {
        key: 13,
        value: 13,
        text: "承接A类项目经理",
        number: 15,
    },
    14: {
        key: 14,
        value: 14,
        text: "承接B类项目经理",
        number: 10,
    },
    15: {
        key: 15,
        value: 15,
        text: "承接C类项目经理",
        number: 5,
    },
    16: {
        key: 16,
        value: 16,
        text: "其他",
        number: 1,
    },
};

export const CUT_LIST = [
    "有限",
    "公司",
    "网络",
    "信息",
    "技术",
    "上海市",
    "上海",
    "北京市",
    "北京",
    "科技",
    "股份",
    "责任",
    "南京",
    "服务",
    "中国",
    "(",
    ")",
    "行业",
    "协会",
    "福建",
    "广州",
    "南京",
    "）",
    "（",
    "广西",
    "南宁",
    "环保",
    "电子",
    "市",
    "物流",
    "通讯",
    "教育",
    "培训",
    "管理",
    "餐饮",
    "商贸",
    "金融",
    "国际",
    "安全",
    "消防",
    "航空",
    "浙江",
    "集团",
    "通信",
    "投资",
    "视讯",
    "机电",
    "贸易",
    "物联网",
    "制造",
    "化妆品",
    "地产",
    "代理",
    "货运",
    "投资",
    "医疗",
    "实业",
    "咨询",
    "工业",
    "置业",
    "文化",
    "）",
    "松江区",
    "松江",
    "软件",
    "系统",
    "大厦",
    "经营",
    "机器",
    "设计",
    "眼镜",
    "仪器",
    "仪表",
    "物业",
    "基金",
    "游戏",
    "销售",
    "医药",
    "旅行",
    "医学",
    "数据",
    "酒店",
    "委员会",
    "研究院",
    "卫生中心",
    "保障局",
    "虹口区",
    "虹口",
    "金山区",
    "金山",
    "静安区",
    "静安",
    "工场",
    "汽车",
    "浦东新区",
    "浦东区",
    "浦东",
    "厦门",
    "闵行",
    "深圳",
    "苏州",
    "医用",
    "工程",
    "苏州",
    "成都",
    "学校",
    "计算",
    "银行",
    "宝山区",
    "宝山",
    "崇明区",
    "崇明",
    "保险",
    "医院",
    "公安局",
    "青浦区",
    "青浦",
    "黄浦区",
    "黄浦",
    "徐汇区",
    "徐汇",
    "长宁区",
    "长宁",
    "普陀区",
    "普陀",
    "杨浦区",
    "杨浦",
    "闵行区",
    "闵行",
    "嘉定区",
    "嘉定",
    "金山区",
    "金山",
    "奉贤区",
    "奉贤",
];

export const WEAPONDEPARTMENT = [
    {
        key: 6,
        value: "104596498",
        text: "产品一部",
    },
    {
        key: 1,
        value: "104596499",
        text: "产品二部",
    },
    {
        key: 2,
        value: "104596501",
        text: "安全服务部",
    },
    {
        key: 3,
        value: "104596503",
        text: "运营部",
    },
    {
        key: 4,
        value: "104596496",
        text: "其它",
    },
    {
        key: 5,
        value: "591877050",
        text: "安全意识部"
    },
    {
        key: 7,
        value: "501605326",
        text: "经管办"
    },
];

export const WEAPONDEPARTMENT_OBJ = {
    104596499: "产品二部",
    104596501: "安全服务部",
    104596503: "运营部",
    104596496: "其它",
    591877050: "安全意识部",
    104596498: "产品一部",
    501605326: "经管办",
};

// 作弊打卡
export const CHEATTYPE = [
    {
        key: 1,
        value: 1,
        text: "他人代打",
    },
    {
        key: 2,
        value: 2,
        text: "模拟打卡",
    },
    {
        key: 3,
        value: 3,
        text: "平面图打卡",
    },
    {
        key: 4,
        value: 4,
        text: "模拟定位",
    },
    {
        key: 5,
        value: 5,
        text: "拍照内容不符合要求",
    },
    {
        key: 6,
        value: 6,
        text: "业务无关特殊卡",
    },
    {
        key: 7,
        value: 7,
        text: "虚假客户卡",
    },
    {
        key: -1,
        value: -1,
        text: "其他违规行为",
    },
];
export const AVERAGE_TYPE = [
    {
        key: 1,
        value: 1,
        text: "研发均摊",
    },
    {
        key: 2,
        value: 2,
        text: "工具均摊",
    },
    {
        key: 3,
        value: 3,
        text: "云资源均摊",
    },
    {
        key: 4,
        value: 4,
        text: "交通费",
    },
    {
        key: 5,
        value: 5,
        text: "开拓费用",
    },
];

export const AVERAGE_TYPE_OBJ = {
    1: "研发均摊",
    2: "工具均摊",
    3: "云资源均摊",
    4: "交通费",
    5: "开拓费用",
};
export const PROJECT_TIME = [
    {
        key: 1,
        value: 1,
        text: "立即",
    },
    {
        key: 2,
        value: 2,
        text: "3个月内",
    },
    {
        key: 3,
        value: 3,
        text: "半年内",
    },
    {
        key: 4,
        value: 4,
        text: "1年内",
    },
    {
        key: 5,
        value: 5,
        text: "2年内",
    },
    {
        key: 6,
        value: 6,
        text: "未知时间",
    },
];

export const PROJECT_TIME_OBJ = {
    1: {
        key: 1,
        value: 1,
        text: "立即",
    },
    2: {
        key: 2,
        value: 2,
        text: "3个月内",
    },
    3: {
        key: 3,
        value: 3,
        text: "半年内",
    },
    4: {
        key: 4,
        value: 4,
        text: "1年内",
    },
    5: {
        key: 5,
        value: 5,
        text: "2年内",
    },
    6: {
        key: 6,
        value: 6,
        text: "未知时间",
    },
};

export const REQUIREMENT = [
    {
        key: 1,
        value: 1,
        text: "清晰",
    },
    {
        key: 2,
        value: 2,
        text: "不清晰",
    },
];

export const REQUIREMENT_OBJ = {
    1: {
        key: 1,
        value: 1,
        text: "清晰",
    },
    2: {
        key: 2,
        value: 2,
        text: "不清晰",
    },
};

export const BUDGET_RANGE = [
    {
        key: 1,
        value: 1,
        text: "0-1万",
    },
    {
        key: 2,
        value: 2,
        text: "1万-10万",
    },
    {
        key: 3,
        value: 3,
        text: "10万-100万",
    },
    {
        key: 4,
        value: 4,
        text: "100万-1000万",
    },
    {
        key: 5,
        value: 5,
        text: "1000万-10000万",
    },
];

export const BUDGET_RANGE_OBJ = {
    1: {
        key: 1,
        value: 1,
        text: "0-1万",
    },
    2: {
        key: 2,
        value: 2,
        text: "1万-10万",
    },
    3: {
        key: 3,
        value: 3,
        text: "10万-100万",
    },
    4: {
        key: 4,
        value: 4,
        text: "100万-1000万",
    },
    5: {
        key: 5,
        value: 5,
        text: "1000万-10000万",
    },
};

export const KP_LEVEL = [
    {
        key: 1,
        vlaue: 1,
        text: "第一层",
    },
    {
        key: 2,
        vlaue: 2,
        text: "第二层",
    },
    {
        key: 3,
        vlaue: 3,
        text: "第三层",
    },
    {
        key: 4,
        vlaue: 4,
        text: "第四层",
    },
    {
        key: 5,
        vlaue: 5,
        text: "第五层",
    },
];

export const KP_LEVEL_OBJ = {
    1: {
        key: 1,
        vlaue: 1,
        text: "第一层",
    },
    2: {
        key: 2,
        vlaue: 2,
        text: "第二层",
    },
    3: {
        key: 3,
        vlaue: 3,
        text: "第三层",
    },
    4: {
        key: 4,
        vlaue: 4,
        text: "第四层",
    },
    5: {
        key: 5,
        vlaue: 5,
        text: "第五层",
    },
};

export const PHONE_FORMAT = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;

export const INVOICE_CONTENT = [
    {
        key: 1,
        value: 1,
        text: "技术服务费",
    },
    {
        key: 2,
        value: 2,
        text: "安全服务费",
    },
    {
        key: 3,
        value: 3,
        text: "咨询服务费",
    },
    {
        key: 4,
        value: 4,
        text: "技术开发费",
    },
    {
        key: -1,
        value: -1,
        text: "其他",
    },
];

export const INVOICE_CONTENT_OBJ = {
    1: "技术服务费",
    2: "安全服务费",
    3: "咨询服务费",
    4: "技术开发费",
    "-1": "其他",
};

export const INVOICE_APPROVE_STAGE = [
    {
        url: "/invoice-business",
        stage: 1,
    },
    {
        url: "/invoice-service",
        stage: 2,
    },
    {
        url: "/invoice-finance",
        stage: 3,
    },
    {
        url: "/invoice-enter",
        stage: 4,
    },
];

export const DELIVERY_METHOD = [
    {
        key: 1,
        value: 1,
        text: "快递",
    },
    {
        key: 2,
        value: 2,
        text: "当面交付",
    },
    {
        key: -1,
        value: -1,
        text: "其他",
    },
];

export const DELIVERY_METHOD_OBJ = {
    1: "快递",
    2: "当面交付",
    3: "其他",
};

export const SEAL_LIST = [
    {
        key: "公章、法人章",
        value: "公章、法人章",
        text: "公章、法人章",
    },
    {
        key: "合同章、财务章",
        value: "合同章、财务章",
        text: "合同章、财务章",
    },
    {
        key: "人事章、劳动合同章",
        value: "人事章、劳动合同章",
        text: "人事章、劳动合同章",
    },
    {
        key: "人事章（2）、劳动合同章",
        value: "人事章（2）、劳动合同章",
        text: "人事章（2）、劳动合同章",
    },
    {
        key: "行政章",
        value: "行政章",
        text: "行政章",
    },
    {
        key: "报价章",
        value: "报价章",
        text: "报价章",
    },
    {
        key: "运营章",
        value: "运营章",
        text: "运营章",
    },
];

export const LEVEL_ARR = [null, "未分级", "C级", "B级", "A级", "S级"];

export const BADGE = [
    badge0,
    badge1, //1-> 20
    badge6,
    badge3, //3-> 4
    badge3,
    badge5, //5-> 19
    badge6, //6-> 2
    badge7, //7-> 17
    badge8, //8-> 18
    badge9, //9-> 14
    badge10,//10-> 21
    null,
    badge12,
    null,
    badge9,
    badge15,
    badge16,
    badge7,
    badge8,
    badge5,
    badge1,
    badge10,
]; //将徽章存到数组里
export const BADGEOBJ = {
    '4': badge10,
    '30': badge0,
    '31': badge1,
    '32': badge3,
    '33': badge5,
    '34': badge6,
    '35': badge7,
    '36': badge8,
}
export const BADGE_COLOR = [
    null,
    "rgb(249,180,62)",
    "rgb(139,225,82)",
    "rgb(244,104,125)",
    "rgb(244,104,125)",
    "rgb(142,132,225)",
    "rgb(139,225,82)",
    null,
    null,
    null,
    "rgb(240,190,214)",
    null,
    "rgb(212,241,244)",
    null,
    "rgb(251,63,181)",
    "rgb(99,101,66)",
    "rgb(37,102,102)",
    "rgb(13,173,238)",
    "rgb(248,146,40)",
    "rgb(142,132,225)",
    "rgb(249,180,62)",
    "rgb(240,190,214)",
];

export const CONTRACT_STANDARD = [
    {
        key: 1,
        value: 1,
        text: "标准合同",
    },
    {
        key: 2,
        value: 2,
        text: "非标准合同",
    },
];

export const CONTRACT_STANDARD_OBJ = {
    1: "标准合同",
    2: "非标准合同",
};

export const CASH_RATIO = 0.85;

export const INTRODUCE_PRODUCTS = [
    {
        key: 1,
        value: 1,
        text: "漏洞之眼",
    },
    {
        key: 2,
        value: 2,
        text: "一云",
    },
    {
        key: 3,
        value: 3,
        text: "内网巡检",
    },
    {
        key: 4,
        value: 4,
        text: "漏洞银行众测",
    },
    {
        key: 5,
        value: 5,
        text: "数据安全托管",
    },
    {
        key: 6,
        value: 6,
        text: "漏洞银行SRC",
    },
    {
        key: 7,
        value: 7,
        text: "红蓝对抗",
    },
    {
        key: 8,
        value: 8,
        text: "其他安服",
    },
    {
        key: 9,
        value: 9,
        text: "玖克产品",
    },
]

export const INTRODUCE_PRODUCTS_OBJ = {
    1: '漏洞之眼',
    2: '一云',
    3: '内网巡检',
    4: '漏洞银行众测',
    5: '数据安全托管',
    6: '漏洞银行SRC',
    7: '红蓝对抗',
    8: '其他安服',
    9: '玖克产品',
    '-1': '无',
}

export const VISIT_NATURE_TYPE = [
    {
        key: 1,
        value: 1,
        text: "老客户再次拜访"
    },
    {
        key: 2,
        value: 2,
        text: "老客户新需求"
    },
    {
        key: 3,
        value: 3,
        text: "新客户"
    },
];

export const VISIT_NATURE_TYPE_OBJ = {
    1: "老客户再次拜访",
    2: "老客户新需求",
    3: "新客户",
};

export const EXPIRATION_DAYS = 60;

export const BUSINESS_TO_PIPELINE = {
    8: 4,
    9: 5,
    10: 6,
    11: 7,
    12: 8,
    13: 9,
    6: 6,
    7: 7,
}

export const GO_OUT_CHECK_OBJ = {//不在这个白名单且有管理员权限的可以筛选三种类型的审批
    "罗清篮": "CEO",
    "鲍晓南": "COO",
    "张雪松": "CTO",
    "张琦": "ASS",
    "宋秋云": "COO",
}

export const PUSH_WHITELIST = ["鲍晓南", "庄琳筠"];

export const ABILITY_RADAR_CONFIG_OBJ = {
    "忠诚度与职业素养": {
        text: "忠诚度与职业素养",
        score: "10",
        percent: 2,
        en: "loyaltyAndProfessionalism",
    },

    "客户与信息管理": {
        text: "客户与信息管理",
        score: "20",
        percent: 2,
        en: "customerAndInformationManagement",
    },
    "勤奋度与执行力": {
        text: "勤奋度与执行力",
        score: "20",
        percent: 2,
        en: "diligenceAndExecution",
    },
    "市场洞察与开发": {
        text: "市场洞察与开发",
        score: "10",
        percent: 1,
        en: "marketInsightAndDevelopment",
    },
    "主动和亲和力": {
        text: "主动和亲和力",
        score: "20",
        percent: 2,
        en: "proactivityAndApproachability",
    },
    "合作沟通": {
        text: "合作沟通",
        score: "10",
        percent: 1,
        en: "cooperationAndCommunication",
    },
}

export const ABILITY_RADAR_CONFIG = {
    loyaltyAndProfessionalism: "忠诚度与职业素养",
    customerAndInformationManagement: "客户与信息管理",
    diligenceAndExecution: "勤奋度与执行力",
    marketInsightAndDevelopment: "市场洞察与开发",
    proactivityAndApproachability: "主动和亲和力",
    cooperationAndCommunication: "合作沟通",
}

export const CUSTOMER_LEVEL_OBJ = {
    0: "未分类",
    1: "SMB",
    2: "KA",
    3: "MA",
    4: '无效',
    5: '风险企业',
    6: '失信企业'
}

export const CUSTOMER_LEVEL = [
    {
        key: 3,
        value: 3,
        text: "MA"
    },
    {
        key: 2,
        value: 2,
        text: "KA"
    },
    {
        key: 1,
        value: 1,
        text: "SMB"
    },
    {
        key: 0,
        value: 0,
        text: "未分类"
    },
    {
        key: 4,
        value: 4,
        text: "无效"
    },
    {
        key: 5,
        value: 5,
        text: "风险企业"
    },
    {
        key: 6,
        value: 6, 
        text: "失信企业"
    },
];

export const CUSTOMER_TYPE_OBJ = {
    0: "未分类",
    1: "自拓",
    2: "公司",
    3: "高管",
    4: "渠道",
    5: "子公司",
    6: "协会",
    7: "其他",
}

export const CUSTOMER_TYPE = [
    {
        key: 5,
        value: 5,
        text: "子公司"
    },
    {
        key: 4,
        value: 4,
        text: "渠道"
    },
    {
        key: 3,
        value: 3,
        text: "高管"
    },
    {
        key: 2,
        value: 2,
        text: "公司"
    },
    {
        key: 1,
        value: 1,
        text: "自拓"
    },
    {
        key: 6,
        value: 6,
        text: "协会"
    },
    {
        key: 7,
        value: 7,
        text: "其他"
    },
    {
        key: 0,
        value: 0,
        text: "未分类"
    },
];

export const REPEAT_ORDER = [
    {
        key: "正常",
        value: "正常",
        text: "正常",
    },
    {
        key: "今年未续费",
        value: "今年未续费",
        text: "今年未续费",
    },
    {
        key: "两年未续费",
        value: "两年未续费",
        text: "两年未续费",
    },
    {
        key: "未成交",
        value: "未成交",
        text: "未成交",
    },
];

export const CLOSED_REASON = [
    {
        key: 1,
        value: 1,
        text: "无效客户",
    },
    {
        key: 2,
        value: 2,
        text: "企业状态不良",
    },
    {
        key: 3,
        value: 3,
        text: "企业预算小",
    },
]

export const CLOSED_REASON_OBJ = {
    1: "无效客户",
    2: "企业状态不良",
    3: "企业预算小",
};

export const KP_CLOSED_REASON = [
    {
        key: 1,
        value: 1,
        text: "信息有误",
    },
    {
        key: 2,
        value: 2,
        text: "岗位调动",
    },
    {
        key: 3,
        value: 3,
        text: "联系方式改变",
    },
    {
        key: 4,
        value: 4,
        text: "影响力不足",
    },
]

export const KP_CLOSED_REASON_OBJ = {
    1: "信息有误",
    2: "岗位调动",
    3: "联系方式改变",
    4: "影响力不足",
};

export const KP_PROGRESS = [
    {
        key: 0,
        value: 0,
        text: "暂无进展",
    },
    {
        key: 1,
        value: 1,
        text: "1级：单独拜访/技术拜访",
    },
    {
        key: 2,
        value: 2,
        text: "2级：单独约饭",
    },
    {
        key: 3,
        value: 3,
        text: "3级：出席晚宴/填补结构洞",
    },
    {
        key: 4,
        value: 4,
        text: "4级：分配预算/关系引荐",
    },
    {
        key: 5,
        value: 5,
        text: "5级：合作共赢",
    },
];

export const KP_PROGRESS_OBJ = {
    0: "暂无进展",
    1: "1级：单独拜访/技术拜访",
    2: "2级：单独约饭",
    3: "3级：出席晚宴/填补结构洞",
    4: "4级：分配预算/关系引荐",
    5: "5级：合作共赢",
};

export const SELL_SERVICE_TYPE = [
    {
        value: 1,
        label: "项目承做",
        children: [
            { key: 1, value: 6, label: "承担A类客户项目经理" },
            { key: 2, value: 7, label: "承担B类客户项目经理" },
            { key: 3, value: 8, label: "承担C类客户项目经理" },
            { key: 4, value: 4, label: "安全日策划类活动项目管理" },
            { key: 5, value: 5, label: "驻场类(驻场咨询/驻场管理项目)" },
        ],
    },
    {
        value: 2,
        label: "项目承揽",
        children: [
            { key: 1, value: 1, label: "项目经理陪访(现场/首次电话会议)" },
            { key: 2, value: 2, label: "可研报告/方案/标书" },
            { key: 3, value: 3, label: "制作新产品PPT/白皮书/产品使用手册" },
            { key: 4, value: 9, label: "其他(请说明)" },
        ],
    },
];
//支撑项目  9其他  1项目经理陪访 2可研报告/方案/标书 3制作新产品PPT /白皮书/产品使用手册  4安全日策划类活动项目管理  5驻场类（驻场咨询、驻场管理项目）6承接A类项目经理 7承接B类项目经理 8承接C类项目经理


export const RANKNAME_OBJ = {
    0: '综合',
    1: '毛利',
    2: '完成率',
    3: '勤奋度',
    4: '新KP',
    5: '续签率',
    6: '费效比',
    7: '毛利率',
    8: '转化率',
    9: '时统计',
    10: '拜访量',
    11: '里程数',
    12: '新增项目',
    13: '进展项目',
    14: '赢单项目',
    15: '健康度',
    16: '总监管理',
    17: '触达量',
}

export const RANKDISPLAY_OBJ = {
    1: '毛利：',
    10: '拜访量：',
}

export const DEPARTMENT_TEAM_OBJ = {//奖金赛和首页排行榜相关，两者可能会有交集
    'undisplayDepartment': [4, 11, -1, 13, 14, 15, 16],//用在奖金赛的显示
    'displayDepartment': [1, 2, 3, 5, 6, 7, 8, 9, 10, 12, 17, 18, 19, 20, 21],//用在排行榜的显示
}

export const MARKETCOST_TYPE = [
    {
        key: 1,
        value: 1,
        text: 'KQ'
    },
    {
        key: 2,
        value: 2,
        text: 'ZD'
    },
    {
        key: 3,
        value: 3,
        text: 'LP'
    },
    {
        key: 4,
        value: 4,
        text: 'QT'
    },
]

export const MARKETCOST_TYPE_OBJ = {
    1: 'KQ',
    2: 'ZD',
    3: 'LP',
    4: 'QT',
}

export const MARKETCOST_FORM = [
    {
        key: 1,
        value: 1,
        text: 'DG'
    },
    {
        key: 2,
        value: 2,
        text: 'JDK'
    },
    {
        key: 3,
        value: 3,
        text: 'XJ'
    },
    {
        key: 4,
        value: 4,
        text: 'QT'
    },
]

export const MARKETCOST_FORM_OBJ = {
    1: 'DG',
    2: 'JDK',
    3: 'XJ',
    4: 'QT',
}


/*新日报*/
export const CUSTOMER_RIGHT = [
    {
        key: 1,
        value: 1,
        text: "管理（可立项）"
    },
    {
        key: 2,
        value: 2,
        text: "高层（可决策）"
    },
    {
        key: 3,
        value: 3,
        text: "其他非KP"
    },
];
export const CUSTOMER_RIGHT_OBJ = {
    1: '管理（可立项）',
    2: '高层（可决策）',
    3: '其他非KP',
};
export const CUSTOMER_ACTIVE = [
    {
        key: 1,
        value: 1,
        text: "高"
    },
    {
        key: 2,
        value: 2,
        text: "中"
    },
    {
        key: 3,
        value: 3,
        text: "低"
    },
];
export const CUSTOMER_ACTIVE_OBJ = {
    1: '高',
    2: '中',
    3: '低',
};
export const VISITSTATE_OPPOSITE = {
    "目标客户": 1,
    "KP录入": 2,
    "商机报备": 3,
    "冷线索": 3.1,
    "热线索": 3.2,
    "项目立项": 4,
    "毛利核算": 5,
    "决策层公关": 6,
    "方案评选": 7,
    "合同申请": 8,
    "等待赢单": 9,
    "赢单": 10,
    "输单": 11,
};
//日报行动项
export const ACTION_ITEM = [//clientRequired客户是否必选
    {
        key: 1,
        value: 1,
        clientRequired: true,
        text: "现场交流",
    },
    {
        key: 2,
        value: 2,
        clientRequired: true,
        text: "线上交流",
    },
    {
        key: 3,
        value: 3,
        clientRequired: true,
        text: "标准方案",
    },
    {
        key: 4,
        value: 4,
        clientRequired: true,
        text: "非标准方案",
    },
    {
        key: 5,
        value: 5,
        clientRequired: true,
        text: "标准合同",
    },
    {
        key: 6,
        value: 6,
        clientRequired: true,
        text: "非标准合同",
    },
    {
        key: 21,
        value: 21,
        clientRequired: true,
        text: "添加项目备注",
    },
    {
        key: 7,
        value: 7,
        clientRequired: true,
        text: "交付工作",
    },
    {
        key: 8,
        value: 8,
        clientRequired: true,
        text: "满意度调查",
    },
    {
        key: 9,
        value: 9,
        clientRequired: true,
        text: "验收报告",
    },
    {
        key: 10,
        value: 10,
        clientRequired: true,
        text: "项目归档",
    },
    {
        key: 11,
        value: 11,
        clientRequired: true,
        text: "成本核算",
    },
    {
        key: 12,
        value: 12,
        clientRequired: false,
        text: "预约/临时外出",
    },
    {
        key: 13,
        value: 13,
        clientRequired: true,
        text: "创建/完善kp",
    },
    {
        key: 14,
        value: 14,
        clientRequired: true,
        text: "创建/完善客户",
    },
    {
        key: 15,
        value: 15,
        clientRequired: true,
        text: "推进pipeline",
    },
    {
        key: 16,
        value: 16,
        clientRequired: false,
        text: "客户卡",
    },
    {
        key: 17,
        value: 17,
        clientRequired: false,
        text: "出门卡",
    },
    {
        key: 18,
        value: 18,
        clientRequired: false,
        text: "特殊会议卡",
    },
    {
        key: 19,
        value: 19,
        clientRequired: false,
        text: "特殊客户卡",
    },
    {
        key: 20,
        value: 20,
        clientRequired: false,
        text: "办公室卡",
    },
];

export const ACTION_ITEM_OBJ = {
    1: "现场交流",
    2: "线上交流",
    3: "标准方案",
    4: "非标准方案",
    5: "标准合同",
    6: "非标准合同",
    7: "交付工作",
    8: "满意度调查",
    9: "验收报告",
    10: "项目归档",
    11: "成本核算",
    12: "预约/临时外出",
    13: "创建/完善kp",
    14: "创建/完善客户",
    15: "推进pipeline",
    16: "客户卡",
    17: "出门卡",
    18: "特殊会议卡",
    19: "特殊客户卡",
    20: "办公室卡",
    21: "添加项目备注",
}

export const RISKY_TYPE = [
    {
        key: 1,
        value: 1,
        text: "无销售合同",
    },
    {
        key: 2,
        value: 2,
        text: "上游成本与采购金额不一致",
    },
];

export const RISKY_TYPE_OBJ = {
    1: "无销售合同",
    2: "上游成本与采购金额不一致",

    101: "缺少甲方对接人",
    102: "已赢单，销售合同未上传",
    103: "已赢单，采购合同未上传",
    104: "已赢单，用代替合同进行上传",
    201: "成本无法覆盖",
    202: "毛利率过低",
    203: "超时未回款",
    204: "严重超时未回款",
    205: "供应商付款非背靠背",
    206: "供应商付款金额目前超出回款金额",
    207: "甲方核价尾款金额减少",
    208: "费用预支",
    301: "无销售合同，已开始交付",
    302: "无销售合同，已签订供应商合同",
    303: "未按合同完整交付",
    304: "存在合同变更",
    305: "存在补充协议",
    306: "存在供应商变更/解约重签",
    307: "存在设备借用未收回",
    401: "合同提前终止",
    402: "客户倒闭",
    501: "疑似输单",
    502: "长期未推进",
    503: "KP对接不上",
    504: "重点项目",
    505: "成本审批变化",
}

export const RISKY_TYPE_GROUP = [
    {
        text: '内容不全',
        value: '内容不全',
        children: [
            {
                text: '缺少甲方对接人',
                value: 101,
            },
            {
                text: '已赢单，销售合同未上传',
                value: 102,
            },
            {
                text: '已赢单，采购合同未上传',
                value: 103,
            },
            {
                text: '已赢单，用代替合同进行上传',
                value: 104,
            },
        ]
    },
    {
        text: '财务风险',
        value: '财务风险',
        children: [
            {
                text: '成本无法覆盖',
                value: 201,
            },
            {
                text: '毛利率过低',
                value: 202,
            },
            {
                text: '超时未回款',
                value: 203,
            },
            {
                text: '严重超时未回款',
                value: 204,
            },
            {
                text: '供应商付款非背靠背',
                value: 205,
            },
            {
                text: '供应商付款金额目前超出回款金额',
                value: 206,
            },
            {
                text: '甲方核价尾款金额减少',
                value: 207,
            },
            {
                text: '费用预支',
                value: 208,
            },
        ]
    },
    {
        text: '交付风险',
        value: '交付风险',
        children: [
            {
                text: '无销售合同，已开始交付',
                value: 301,
            },
            {
                text: '无销售合同，已签订供应商合同',
                value: 302,
            },
            {
                text: '未按合同完整交付',
                value: 303,
            },
            {
                text: '存在合同变更',
                value: 304,
            },
            {
                text: '存在补充协议',
                value: 305,
            },
            {
                text: '存在供应商变更/解约重签',
                value: 306,
            },
            {
                text: '存在设备借用未收回',
                value: 307,
            },
        ]
    },
    {
        text: '法务风险',
        value: '法务风险',
        children: [
            {
                text: '合同提前终止',
                value: 401,
            },
            {
                text: '客户倒闭',
                value: 402,
            },
        ]
    },
    {
        text: '项目风险',
        value: '项目风险',
        children: [
            {
                text: '疑似输单',
                value: 501,
            },
            {
                text: '长期未推进',
                value: 502,
            },
            {
                text: 'KP对接不上',
                value: 503,
            },
            {
                text: '重点项目',
                value: 504,
            },
            {
                text: '成本审批变化',
                value: 505,
            },
        ]
    },
];

export const CONTRACT_RISKY_TYPE_OBJ = {
    102: "销售合同未关联项目",
    103: "采购合同未关联项目",
}

export const CONTRACT_RISKY_TYPE_GROUP = [
    {
        text: '内容不全',
        value: '内容不全',
        children: [
            {
                text: '销售合同未关联项目',
                value: 102,
            },
            {
                text: '采购合同未关联项目',
                value: 103,
            },
        ]
    }
];

export const CUSTOMER_ACTION_NAME = ["创建客户信息", "跟进客户", "退跟客户", "完善客户信息", "客户交流", "其他"];

export const KP_ACTION_NAME = ["创建KP信息", "完善KP信息", "KP拜访", "KP交流"];

export const PROJECT_ACTION_NAME = ["创建项目信息", "完善项目信息", "项目赢单", "成本审批", "合同出具", "合同签订", "发票开具", "项目回款", "添加项目备注", "方案制作", "SOW制作", "报价单出具", "招投标", "启动会", "服务验收单", "满意度调研", "项目复盘归档"];

export const SELLER_INVISIBLE_ACTION_NAME = ["退跟客户"];

export const PROJECT_MANAGER_INVISIBLE_ACTION_NAME = ["创建客户信息", "完善客户信息", "创建KP信息", "完善KP信息"];

export const TOOLS_PRODUCT_OBJ = {
    "Chat": "ChatGPT",
    "picture": "DALLE",
}

export const SELLER_CONTRACT = [1, 6];

export const PROCUREMENT_CONTRACT = [2, 7, 8];

export const RANKLIST_MAX_DATA = {
    month: {
        name: "汪晨",
        value: "670,708.51",
    },
    quarter: {
        name: "孙强",
        value: "811,317.80",
    },
    year: {
        name: "孙强",
        value: "1,607,714.45",
    },
}

export const BUSINESS_BELONG = {
    1: '个人类',
    2: '合作类',
    3: '继承类'
}
let businessBelongFilter = [];
for (let i in BUSINESS_BELONG) {
    businessBelongFilter.push({
        value: i,
        text: BUSINESS_BELONG[i]
    });
}
export const BUSINESS_BELONG_FILTER = businessBelongFilter;

// 客户表新增字段
export const CUSTOMER_ACTIVITY = { //客户活跃度
    1: '6个月以内',
    2: '6-12个月',
    3: '12个月以上'
};
export const CUSTOMER_MAGNITUDE = { //公司量级
    1: '2000万以上',
    2: '2000-1000万',
    3: '1000-500万',
    4: '500-300万',
    5: '300-100万',
    6: '100万以下',
};
export const CUSTOMER_INDUSTRY = { //行业划分
    1: '政企（数据局，央企，国企，各委办局等）',
    2: '外企、头部企业，上市公司',
    3: '私企（中小型）等其他企业',
    4: '监管部门',
    5: 'AI需求',
};
export const FINISH_STATUS = { //回款状态
    1: '正常回款',
    2: '终止合作',
    3: '坏账',
    4: '其他'
}