const msgData = {
    "dailyWorkReport": {/**日报审批 */

        "business_director": 0/**日报审批 */
    },
    "preSalesApplication": {
        /**售前申请审批 */
        "pre_sales_manager": 0 /**售前主管 */
    },
    "contractApplication": {/**合同申请审批*/
        "project_manager": 0,/**项目经理 */
        "product": 0,/**交付部门审批 */
        "services": 0,/**交付部门审批 */
        "operate": 0,/**交付部门审批 */
        "business_affairs": 0,/**交付部门审批 */
        "finance": 0/**合同法务审核员 */
    },
    "marketingCostApplication": {/**费用申请审批 */
        "finance": 0,/**市场费财务审批 */
        "self": 0/**商务部总经理 */
    },
    "stepBackApplication": {/**退跟审批 */
        "business_director": 0/**退跟审批 */
    },
    "winApplication": {/**赢单审批 */
        "win_examiner": 1/***赢单审批 */
    },
    "goOutApplication": {/**出门申请审批 */
        "department": 0,/**团队 */
        "CEO": 0,/**CEO */
        "business_director": 0/**商务部总经理 */
    },
    "quotation": {/**报价单审批 */
        "quotation_examiner": 0,/**报价单审批员 */
        "seller": 0,/**销售上传 */
        "sales_director": 0/**销售总监审批*/
    },
    "costApplication": {/**成本审批 */
        "return_to_factory": 0,/**成本审批 */
        "marketing_cost": 0,/**市场费用审批 */
        "cost_new": 0,/**成本审批 */
        "other_cost": 2,/**成本审批 */
        "project_manager": 0,/**成本审批 */
        "inspector": 0,/**成本审批 */
        "admin": 0,/**成本审批 */
        "seller": 0/**成本审批 */
    },
    "loseApplication": {
        lose_examiner: 0/**输单审批 */
    }
}
export const todoUrlRefer = {
    dailyWorkReport: {
        name: "日报批阅",
        url: '/crm/approve/work-log-apply/list'
    },
    preSalesApplication: {
        name: "售前申请审批",
        url: "/crm/pre-sales/apply/list"
    },
    contractApplication: {
        name: '合同审批',
        children: {
            project_manager: {
                name: '合同项目经理审批',
                url: '/crm/approve/pm-approve/list'
            },
            finance: {
                name: '合同法务审批',
                url: '/crm/approve/law-approve/list'
            }
        },
        default: {
            name: '合同交付部门审批',
            url: '/crm/approve/technology-approve/list'
        }
    },
    marketingCostApplication: {
        name: '费用申请审批',
        children: {
            finance: {
                name: '费用财务审批',
                url: '/crm/approve/marketCost-finance/list'
            },
            self: {
                name: '费用最终确认',
                url: '/crm/approve/marketCost-final/list'
            }
        }
    },
    stepBackApplication: {
        name: '退跟审批',
        url: '/crm/approve/seller-admin-apply/list'
    },
    winApplication: {
        name: '赢单审批',
        url: '/crm/approve/win-apply/list'
    },
    goOutApplication: {
        name: '出门卡审批',
        children: {
            department: {
                name: "出门卡审批（销售）",
                url: '/crm/approve/go-out-apply/list/seller'
            },
            CEO: {
                name: '出门卡审批（助理）',
                url: '/crm/approve/go-out-apply/list'
            },
            business_director: {
                name: "出门卡审批（助理）",
                url: "/crm/approve/go-out-apply/list"
            }
        }
    },
    quotation: {
        name: "报价单审批",
        children: {
            quotation_examiner: {
                name: "报价单-初步审批",
                url: '/crm/approve/quotation/list'
            },
            seller: {
                name: "报价单-销售上传",
                url: "/crm/salesman/quotation/list"
            },
            sales_director: {
                name: "报价单-销售总监审批",
                url: '/crm/approve/quotation/list'
            }
        }
    },
    costApplication: {
        name: '成本审批',
        url: '/crm/approve/cost-apply/list'
    },
    loseApplication: {
        name: '输单审批',
        url: '/crm/approve/loseApplication/list'
    }
}