/*
 * 包含应用中所有接口调用的函数模块
 * 每个函数的返回值都是promise
 * */

import ajax from "./ajax";

//账号密码登录
export const reqLoginSelf = (data) => ajax("/api/login", data, "POST", { headers: { authorization: undefined } });//不带token
// 登录
export const reqLogin = (data) => ajax("/api/login/dd", data, "POST");
// 注销
export const reqSignout = (data) => ajax("/api/signout", data, "POST");
// 获取个人信息
export const reqSelfInfo = (data) => ajax("/api/user/self ", data, "GET"); // （普通用户）
// 获取部门列表
export const reqDepartmentList = (data) =>
    ajax("/api/department/list", data, "GET"); // （普通用户）/api/department/list
// 获取部门用户列表
export const reqUserList = (data) => ajax("/api/user/list", data, "GET"); // （普通用户）
// 收集前端报错信息
export const reqFrontError = (data) =>
    ajax("/api/front_error/add", data, "POST"); // （普通用户）
//充值接口
export const reqRecharge = (data) => ajax("/api/orderuser", data, "POST"); // （普通用户）
//下载文件接口
export const reqDownloadFile = (data) => ajax("/api/files/new", data, "GET", { responseType: 'blob' }, true); // （普通用户）
//查看除合同外的其他文件
export const reqViewOtherFile=(data)=>ajax( `/api/files/file`,data,'GET',{ responseType: 'blob' }, true)
//获取文件列表接口
export const reqFileList=(data)=>ajax(`/api/files/list`,data,'GET')
//删除文件
export const deleteFile=(data)=>ajax(`/api/files`,data,'DELETE')
// 知识库接口
export const repositoryApi = {
    reqLogin: (data) => ajax("/api/dd/auth", data, "POST"), // 钉钉免登
    // 分类
    reqAllClassifies: (data) => ajax("/api/knowledge/classify/view", data, "GET"), // 获取所有分类列表 （普通用户）
    reqClassifyDelete: (data) => ajax("/api/knowledge/classify", data, "DELETE"), // 删除分类 （普通用户）
    reqClassifyCreate: (data) => ajax("/api/knowledge/classify", data, "POST"), // 创建分类 （普通用户）
    reqClassifyeEdit: (data) => ajax("/api/knowledge/classify", data, "PATCH"), // 修改分类 （普通用户）
    reqClassifyDetail: (data) => ajax("/api/knowledge/classify", data, "GET"), // 获取分类详情 （普通用户）
    reqMyClassifies: (data) => ajax("/api/knowledge/classify/self", data, "GET"), // 获取自己创建的分类 （普通用户）
    // 文章
    reqArticleList: (data) => ajax("/api/knowledge/article/list", data, "GET"), // 获取文章列表 （普通用户）
    reqArticleSearch: (data) =>
        ajax("/api/knowledge/article/search", data, "GET"), // 搜索文章 （普通用户）
    reqArticleDetail: (data) => ajax("/api/knowledge/article", data, "GET"), // 获取文章详情 （普通用户）
    reqArticleAdd: (data) => ajax("/api/knowledge/article", data, "POST"), // 创建文章 （普通用户）
    reqArticleEdit: (data) => ajax("/api/knowledge/article", data, "PATCH"), // 修改文章 （普通用户）
    reqArticleDelete: (data) => ajax("/api/knowledge/article", data, "DELETE"), // 删除文章 （普通用户）
    // 回答
    reqAnswerCreate: (data) => ajax("/api/knowledge/answer", data, "POST"), // 创建回答 （普通用户）
    reqAnswerList: (data) => ajax("/api/knowledge/answer/list", data, "GET"), // 获取回答列表 （普通用户）
    reqAnswerEdit: (data) => ajax("/api/knowledge/answer", data, "PATCH"), // 修改回答 （普通用户）
    reqAnswerDelete: (data) => ajax("/api/knowledge/answer", data, "DELETE"), // 删除回答 （普通用户）
    reqAnswerApprove: (data) =>
        ajax("/api/knowledge/answer/approve", data, "GET"), // 回答点赞 （普通用户）
};

// CRM接口
export const crmApi = {
    reqTodo:()=>ajax(`/api/crm/todo`,{},"GET"),//获取待办列表
    //首页dashboard
    reqGetPayment: (data) =>
        ajax("/api/crm/businessopportunity/payment", data, "GET"), //CRM首页查询回款(CRM管理员、销售)
    reqSelfRanklist: (data) =>
        ajax("/api/crm/businessopportunity/seller/ranking_new", data, "GET"), //CRM首页查询个人排行榜(CRM管理员、销售)
    reqRankData:(data)=>
        ajax("/api/crm/businessopportunity/seller/ranking_new_new",data,'GET'),//获取个人与团队的毛利与拜访量排行榜
    reqOperationLog: (data) =>
        ajax("/api/crm/operation_record_log/list", data, "GET"), //dashboard查看动态(CRM管理员、销售)
    reqPhotoUrl: (data) => ajax("/api/crm/worklog/no_verification", data, "POST"), //上传拍照位置，获得拍照的url(CRM管理员、打卡人员)
    reqPhotoClock: (data) =>
        ajax("/api/crm/worklog/no_verification/work_log", data, "POST"), //拍照打卡(CRM管理员、打卡人员)
    reqFileList: (data) => ajax("/api/files/ue/multifile", data, "POST"), //图片数组上传(CRM管理员、打卡人员)
    reqCheatList: (data) => ajax("/api/crm/publicity/list", data, "GET"), //获取作弊公示区列表(销售、CRM管理员)
    reqGetClient: (data) => ajax("/api/qcc/enterprise_query", data, "GET"), //查询企业信息(销售、CRM管理员)
    reqAdminGetClient: (data) =>
        ajax("/api/qcc/admin/enterprise_query", data, "GET"), //管理员查询企业信息(CRM管理员)
    reqFollowClient: (data) => ajax("/api/crm/customer/follow", data, "PATCH"), //pepiline跟进客户(销售、CRM管理员)
    reqPipelineAdd: (data) =>
        ajax("/api/crm/Pipeline_relationship", data, "POST"), //添加pipeline(销售、CRM管理员)
    reqPipelineDetail: (data) =>
        ajax("/api/crm/Pipeline_relationship", data, "GET"), //查询pipeline阶段信息(销售、CRM管理员)
    reqPipelineDelete: (data) =>
        ajax("/api/crm/Pipeline_relationship", data, "PATCH"), //删除pipeline阶段信息(销售、CRM管理员)
    reqPipelineClockList: (data) =>
        ajax("/api/crm/Pipeline_relationship/details", data, "GET"), //pipeline详情信息(销售、CRM管理员)
    reqPipelineStatistics: (data) =>
        ajax("/api/crm/Pipeline_relationship/statistics", data, "GET"), //pipeline销售漏斗(销售、CRM管理员)
    reqPipelineClient: (data) =>
        ajax("/api/crm/Pipeline_relationship/customer", data, "GET"), //pipeline客户列表(销售、CRM管理员)
    reqPipelineLock: (data) =>
        ajax("/api/crm/Pipeline_relationship/locking", data, "PATCH"), //pipeline锁定客户(销售、CRM管理员)
    reqPipelineWork: (data) =>
        ajax("/api/crm/Pipeline_relationship/work", data, "GET"), //根据客户和kp查pipeline阶段(销售、CRM管理员)
    reqPipelineForecast: (data) =>
        ajax("/api/crm/Pipeline_relationship/forecast", data, "GET"), //pipeline总览预测(销售、CRM管理员)
    reqPipelineBusinessAdd: (data) =>
        ajax(
            "/api/crm/Pipeline_relationship/turn/business_opportunity",
            data,
            "POST"
        ), //事业部总监转让项目 （销售）
    reqPipelineMin: (data) =>
        ajax("/api/crm/Pipeline_relationship/min", data, "GET"), //得到公司和事业部的项目最小值（销售）
    reqPipelineFunnelHealth: (data) =>
        ajax("/api/crm/Pipeline_relationship/funnel_health", data, "GET"), //pipeline个人健康度（销售）
    reqAdminPipelineFunnelHealth: (data) =>
        ajax(
            "/api/crm/Pipeline_relationship/department/funnel_health",
            data,
            "GET"
        ), //pipeline公司、团队健康度（CRM管理员）
    reqDistributeList: (data) =>
        ajax("/api/crm/distribution_records/list", data, "GET"), //销售查询自己分发到的客户列表(销售、CRM管理员)
    reqPipelineFollow: (data) =>
        ajax("/api/crm/Pipeline_relationship/customer/work", data, "GET"), //销售查询派发触达量和自己跟进触达量（销售、CRM管理员）
    reqAdminPipelineFollow: (data) =>
        ajax("/api/crm/Pipeline_relationship/admin/customer/work", data, "GET"), //管理员查询派发触达量和销售自己跟进触达量（事业部总监、CRM管理员）
    reqBusinessProfit: (data) =>
        ajax("/api/crm/seller/gross_profit/customer/business", data, "GET"), //查看销售目标值与真实值的差异（销售、事业部总监、CRM管理员）
    reqDeliverApply: (data) => ajax("/api/crm/Pipeline_relationship/application", data, "PATCH"), //交付部门审批申请(CRM管理员、销售)
    //上传用户手机信息
    reqMobileListInfo: (data) => ajax("/api/user/mobile", data, "PATCH"), //（普通用户）
    reqMobileBind: (data) => ajax("/api/user/binding", data, "PATCH"), //打卡绑定手机（销售）
    reqImgBind: (data) => ajax("/api/user/portrait", data, "PATCH"), //总监上传人脸识别照片模板（团队总监）
    reqMobileIsBind: (data) => ajax("/api/user/verification", data, "GET"), //获取当前手机是否是绑定的手机（销售）
    reqSelf: (data) => ajax("/api/crm/seller/self", data, "GET"), // 获取销售个人信息 （销售）
    reqDDAuth: (data) => ajax("/api/dd/signature?sys=crm", data, "POST"), // 钉钉鉴权 （普通用户）
    reqLogin: (data) => ajax("/api/dd/auth?sys=crm", data, "POST"), // 钉钉免登
    reqTrades: (data) => ajax("/api/crm/trade/list", data, "GET"), // 获取行业列表 （CRM管理员、团队总监、队长、销售、费用审批、安服部审批、项目经理技术支持、HRBP、HRBP助理、财务总监）
    reqClientList: (data) => ajax("/api/crm/customer/list", data, "GET"), // 客户列表 （CRM管理员、团队总监、队长、销售、费用审批、安服部审批、项目经理技术支持、HRBP、HRBP助理、财务总监）
    reqClientAdd: (data) => ajax("/api/crm/customer", data, "POST"), // 添加客户（CRM管理员、销售）
    reqClientEdit: (data) => ajax("/api/crm/customer", data, "PATCH"), // 修改客户（CRM管理员、销售）
    reqClientDetail: (data) => ajax("/api/crm/customer", data, "GET"), // 客户详情 （CRM管理员、团队总监、队长、销售、费用审批、安服部审批、项目经理技术支持、HRBP、HRBP助理、财务总监）
    reqClientBatchAdd: (data) => ajax("/api/crm/customer/batch", data, "PATCH"), // 批量修改客户信息 （公司管理）
    reqClientDelete: (data) => ajax("/api/crm/customer", data, "DELETE"), // 删除客户 （公司管理）
    reqClientBatchDelete: (data) => ajax("/api/crm/customer/batch-delete", data, "POST"), // 批量删除客户 （公司管理）
    // KP管理
    reqKeyPersonAdd: (data) => ajax("/api/crm/keyperson", data, "POST"), // 添加负责人 （CRM管理员、销售）
    reqKeyPersonList: (data) => ajax("/api/crm/keyperson/list", data, "GET"), // 获取负责人列表 （CRM管理员、团队总监、队长、销售、费用审批、安服部审批、项目经理技术支持、HRBP、HRBP助理、财务总监）
    reqKeyPersonDetail: (data) => ajax("/api/crm/keyperson", data, "GET"), // 获取负责人详情 （CRM管理员、团队总监、队长、销售、费用审批、安服部审批、项目经理技术支持、HRBP、HRBP助理、财务总监）
    reqKeyPersonEdit: (data) => ajax("/api/crm/keyperson", data, "PATCH"), // 修改负责人 （CRM管理员、销售）
    reqKeyPersonBatchEdit: (data) => ajax("/api/crm/keyperson/batch", data, "PATCH"), // 批量修改KP （CRM管理员、公司管理、销售）
    reqKeyPersonDelete: (data) => ajax("/api/crm/keyperson", data, "DELETE"), // 删除负责人 （公司管理）
    reqKeyPersonDeleteBatch: (data) => ajax("/api/crm/keyperson/batch-delete", data, "POST"), // 批量删除负责人 （公司管理）
    reqKeyPersonStatisticsPanel: (data) => ajax("/api/crm/keyperson/statistics_panel", data, "GET"), //KP管理统计数据（销售）
    // 工作日志
    reqWorkLogList: (data) => ajax("/api/crm/worklog/admin", data, "GET"), // 管理身份获取工作日志列表 （CRM管理员、MOA管理员）
    reqWorkLogSelf: (data) => ajax("/api/crm/worklog/self", data, "GET"), // 获取自己的工作日志列表 （CRM管理员、打卡人员）
    reqWorkLogAdd: (data) => ajax("/api/crm/worklog", data, "POST"), // 添加工作日志 （CRM管理员、打卡人员）
    reqWorkLogEdit: (data) => ajax("/api/crm/worklog", data, "PATCH"), // 编辑工作日志 （CRM管理员、打卡人员）
    reqWorkLogDelete: (data) => ajax("/api/crm/worklog", data, "DELETE"), // 删除工作日志 （CRM管理员、打卡人员）
    reqWorkLogDetail: (data) => ajax("/api/crm/worklog", data, "GET"), // 获取工作日志详情 （CRM管理员、团队总监、队长、打卡人员、HRBP、HRBP助理）
    reqLocationCheck: (data) => ajax("/api/crm/worklog/location", data, "POST"), // 钉钉地点校验 （CRM管理员、打卡人员）
    reqWorkLogMakeUp: (data) => ajax("/api/crm/worklog/repair", data, "POST"), // 补卡 （CRM管理员、打卡人员）
    reqWorkLogAdmin: (data) => ajax("/api/crm/worklog/admin/list", data, "GET"), // 管理身份获取分组的工作日志列表 （CRM管理员、团队总监、HRBP、HRBP助理）
    reqWeeklyClock: (data) => ajax("/api/crm/worklog/week", data, "GET"), //销售本周的打卡位置
    reqWorkLogCustomer: (data) => ajax("/api/crm/worklog/customer", data, "GET"), // 获取客户拜访阶段 （CRM管理员、销售）
    reqStepOpportunity: (data) =>
        ajax("/api/crm/businessopportunity/work", data, "GET"), //获取客户拜访阶段之后的项目信息（CRM管理员、销售）
    reqVisitSellerList: (data) =>
        ajax("/api/crm/worklog/not_end_work", data, "GET"), //总监随访卡获取附近销售客户卡（团队总监）
    reqGetWorkLog: (data) => ajax("/api/crm/worklog/kp_customer", data, "GET"), //按客户、KP查询该销售是否有打卡 （销售，CRM管理员）
    reqVisitComplaintCheck: (data) => ajax("/api/crm/worklog/review/abnormal", data, "PATCH"), //拜访时间申诉审批 （管理员）
    reqInvalidWorklogList: (data) => ajax("/api/crm/worklog/abnormal/list", data, "GET"), //无效卡公示列表（CRM管理员、销售）

    // 日报
    reqDailyWorkLogInfo: (data) => ajax("/api/crm/daily/work_log", data, "GET"), // 在工作日志中查询填写日报所需要的信息（CRM管理员、打卡人员）
    reqDailyWorkLogList: (data) => ajax("/api/crm/daily/list", data, "GET"), // 管理人员查看日报列表（CRM管理员、团队总监、队长）
    reqDailyWorkLogSelf: (data) => ajax("/api/crm/daily/seller", data, "GET"), // 查看自己的日报列表（CRM管理员、打卡人员）
    reqDailyWorkLogAdd: (data) => ajax("/api/crm/daily", data, "POST"), // 添加日报（CRM管理员、打卡人员）
    reqDailyWorkLogDetail: (data) => ajax("/api/crm/daily", data, "GET"), // 查看日报详情（CRM管理员、团队总监、队长、打卡人员）
    reqDailyWorkLogEdit: (data) => ajax("/api/crm/daily", data, "PATCH"), // 修改日报（CRM管理员、打卡人员）
    reqDailyWorkLogApplyList: (data) => ajax("/api/crm/daily/approval/list", data, "GET"), // 获取日报审批列表（团队总监、队长）
    reqDailyApplyDepartmentList: (data) => ajax("/api/crm/daily/new/inspector/list", data, "GET"), // 团队总监获取新日报审批列表和查看列表（团队总监）
    reqDailyApplyAdminList: (data) => ajax("/api/crm/daily/new/executive/list", data, "GET"), // 商务部总经理获取新日报审批列表（商务部总经理）
    reqDailyViewAdminList: (data) => ajax("/api/crm/daily/new/admin/list", data, "GET"), // 商务部总经理获取新日报查看列表（商务部总经理）
    reqDailyWorkLogReview: (data) => ajax("/api/crm/daily/review", data, "PATCH"), // 日报审批（团队总监、队长）
    reqFaceContrast: (data) => ajax("/api/ks/face_contrast", data, "GET"), // 人脸识别接口（团队总监）
    reqIntroduceAll: (data) => ajax("/api/crm/daily/statistics/extension", data, "GET"), //日报介绍武器总次数（产品审批员、服务审批员、运营审批员、其他武器审批员）
    reqIntroduceToday: (data) => ajax("/api/crm/daily/statistics/day_extension", data, "GET"), //当日日报介绍武器总次数（产品审批员、服务审批员、运营审批员、其他武器审批员）
    reqProgressProductList: (data) => ajax("/api/crm/businessopportunity/deliver/product/list", data, "GET"), //项目进度列表，产品审批员（产品审批员）
    reqProgresssServiceList: (data) => ajax("/api/crm/businessopportunity/deliver/security/list", data, "GET"), //项目进度列表，服务审批员（服务审批员）
    reqProgressOperateList: (data) => ajax("/api/crm/businessopportunity/deliver/operate/list", data, "GET"), //项目进度列表，运营审批员（运营审批员）
    reqProgressAdminList: (data) => ajax("/api/crm/businessopportunity/deliver/admin/list", data, "GET"), //项目进度列表，管理（管理）
    reqProgressOtherList: (data) => ajax("/api/crm/businessopportunity/deliver/pt/list", data, "GET"), //项目进度列表，其他武器审批员（其他武器审批员）
    reqProgressBusinessList: (data) => ajax("/api/crm/businessopportunity/deliver/shw/list", data, "GET"), //项目进度列表，安全意识审批员（安全意识审批员）

    // 周报
    reqWeekWorkLogSelf: (data) => ajax("/api/crm/weekly/seller", data, "GET"), // 查看自己的周报列表（CRM管理员、打卡人员）
    reqWeekWorkLogAdd: (data) => ajax("/api/crm/weekly", data, "POST"), // 添加周报（CRM管理员、打卡人员）
    reqWeekWorkLogDetail: (data) => ajax("/api/crm/weekly", data, "GET"), // 查看周报详情（CRM管理员、团队总监、队长、打卡人员）
    reqWeekWorkLogApplyList: (data) =>
        ajax("/api/crm/weekly/approval/list", data, "GET"), // 获取周报审批列表（团队总监、队长）
    reqWeekWorkLogReview: (data) => ajax("/api/crm/weekly/review", data, "PATCH"), // 周报审批 （团队总监、队长）
    reqWeekWorkLogBusinessList: (data) =>
        ajax("/api/crm/weekly/division/list", data, "GET"), // 团队总监查看周报列表 （团队总监）
    reqWeekWorkLogCaptainList: (data) =>
        ajax("/api/crm/weekly/captain/list", data, "GET"), // 队长查看周报列表 （队长）
    reqWeekWorkLogAdminList: (data) =>
        ajax("/api/crm/weekly/admin/list", data, "GET"), // 管理员查看周报列表 （CRM管理员）
    // 销售管理
    reqSellerList: (data) => ajax("/api/crm/seller/list", data, "GET"), // 获取销售列表 （CRM管理员、团队总监、队长、销售、费用审批、安服部审批、项目经理技术支持、HRBP、HRBP助理、财务总监）
    reqLimitStatus: (data) => ajax("/api/crm/seller/focus_on", data, "PATCH"), //更改销售是否重点关注状态 （CRM管理员）
    reqUserList: (data) => ajax("/api/user/list", data, "GET"), // 获取用户列表
    reqSellerAdd: (data) => ajax("/api/crm/seller", data, "POST"), // 添加销售 （CRM管理员、HRBP、HRBP助理）
    reqSellerDetail: (data) => ajax("/api/crm/seller", data, "GET"), // 获取销售详情 （CRM管理员、团队总监、队长、销售、费用审批、安服部审批、项目经理技术支持、HRBP、HRBP助理、财务总监）
    reqSellerEdit: (data) => ajax("/api/crm/seller", data, "PATCH"), // 修改销售配额（CRM管理员、HRBP、HRBP助理）
    reqSellerDel: (data) => ajax("/api/crm/seller", data, "DELETE"), // 删除销售 （CRM管理员、HRBP）
    reqSellerMsg: (data) => ajax("/api/crm/seller/admin/self", data, "GET"), //获取销售详情（dashboard）（CRM管理员、团队总监）
    reqAdminGetPayment: (data) =>
        ajax("/api/crm/businessopportunity/admin/payment", data, "GET"), //管理员查询销售dashboard回款(CRM管理员、团队总监)
    reqAdminFunnelSelf: (data) =>
        ajax("/api/crm/businessopportunity/admin/funnels/self", data, "GET"), // 管理员获取销售漏斗图 （CRM管理员、团队总监）
    reqGetSellerUser: (data) => ajax("/api/user/admin/seller", data, "GET"), //管理获得销售用户信息
    reqWeaponAdd: (data) => ajax("/api/crm/arsenal", data, "POST"), //添加武器 （CRM管理员，武器库录入员）
    reqWeaponEdit: (data) => ajax("/api/crm/arsenal", data, "PATCH"), //修改武器 （CRM管理员，武器库录入员）
    reqWeaponList: (data) => ajax("/api/crm/arsenal/list", data, "GET"), //武器库列表 （CRM管理员，销售）
    reqWeaponDetail: (data) => ajax("/api/crm/arsenal", data, "GET"), //武器详情 （CRM管理员，销售）
    reqWeaponDelete: (data) => ajax("/api/crm/arsenal", data, "DELETE"), //删除武器 （CRM管理员，武器库录入员）
    reqCaseAdd: (data) => ajax("/api/crm/cases", data, "POST"), //添加案例 （CRM管理员）
    reqCaseEdit: (data) => ajax("/api/crm/cases", data, "PATCH"), //修改案例 （CRM管理员）
    reqCaseList: (data) => ajax("/api/crm/cases/list", data, "GET"), //案例库列表 （CRM管理员，销售）
    reqCaseDetail: (data) => ajax("/api/crm/cases", data, "GET"), //案例详情 （CRM管理员，销售）
    reqCostAdd: (data) => ajax("/api/crm/seller_expenditure", data, "POST"), //添加支出 （CRM管理员，HRBP）
    reqCostEdit: (data) => ajax("/api/crm/seller_expenditure", data, "PATCH"), //编辑支出 （CRM管理员，HRBP）
    reqCostDetail: (data) => ajax("/api/crm/seller_expenditure", data, "GET"), //支出详情 （CRM管理员，HRBP）
    reqCostList: (data) => ajax("/api/crm/seller_expenditure/list", data, "GET"), //销售支出列表 （CRM管理员，HRBP）
    reqInAndCostList: (data) =>
        ajax("/api/crm/businessopportunity/profit/expense", data, "GET"), ////收支列表 （CRM管理员，HRBP）
    reqSelfCostList: (data) =>
        ajax("/api/crm/application/user/list", data, "GET"), //销售的成本审批列表 （销售）
    reqSellerLevelEdit: (data) => ajax("/api/crm/seller/level", data, "PATCH"), // 修改销售等级（HRBP、公司管理）
    reqSeasonDataEdit: (data) => ajax("/api/crm/seller/season_data", data, "PATCH"), //修改季度数据（HRBP、公司管理）
    // 目标管理
    reqTargetEdit: (data) => ajax("/api/crm/target", data, "PATCH"), // 编辑目标 （CRM管理员、HRBP、HRBP助理、销售）
    reqTargetDetail: (data) => ajax("/api/crm/target", data, "GET"), // 获取目标详情 （CRM管理员、HRBP、HRBP助理）
    reqClientSelf: (data) => ajax("/api/crm/customer/target", data, "GET"), // 查看自己跟进的客户列表 （CRM管理员、销售）
    reqClientStatistics: (data) => ajax("/api/crm/customer/target/statistics_panel", data, "GET"), // 查看客户统计 （销售）
    reqClientBusiness: (data) =>
        ajax("/api/crm/customer/department/target", data, "GET"), //团队总监查看本团队下的所有客户（团队总监）
    // 项目管理
    reqBusinessAdd:(data)=>ajax("/api/crm/businessopportunity", data, "POST"), // 添加项目 （销售）
    reqCommercialOpportunity:(data)=>ajax(`/api/crm/commercial_opportunity`,data,"POST"),//创建商机（销售）
    reqCommercialOpportunityList:(data)=>ajax(`/api/crm/commercial_opportunity/list`,data,'GET'),//获取商机列表
    reqCommercialOpportunityDetail:(data)=>ajax(`/api/crm/commercial_opportunity/detail`,data,"GET"),//获取商机详情
    reqEditCommercialOpportunity:(data)=>ajax(`/api/crm/commercial_opportunity`,data,"PATCH"),//修改商机
    reqPreSales:(data)=>ajax(`/api/crm/pre_sales/application`,data,'POST'),//申请售前

    reqProjectBusinessAdd: (data) =>
        ajax("/api/crm/businessopportunity/all", data, "POST"), // 项目合同管理员添加项目 （项目合同管理员）
    reqBusinessList: (data) =>
        ajax("/api/crm/businessopportunity/list", data, "GET"), // 获取项目列表 （CRM管理员、团队总监、队长、销售、费用审批、安服部审批、项目经理技术支持、HRBP、HRBP助理、财务总监）
    reqPreSellerBusinessList: (data) =>
        ajax("/api/crm/businessopportunity/pm", data, "GET"), // 项目经理关联的项目列表 （管理员、项目经理）
    reqBusinessJackList: (data) =>
        ajax("/api/crm/businessopportunity/B_affairs/list", data, "GET"), // 获取项目列表,玖克项目 （玖克项目）
    reqBusinessDetail: (data) =>
        ajax("/api/crm/businessopportunity", data, "GET"), // 获取项目详情 （CRM管理员、团队总监、队长、销售、费用审批、安服部审批、项目经理技术支持、HRBP、HRBP助理、财务总监）
    reqBusinessEdit: (data) =>
        ajax("/api/crm/businessopportunity", data, "PATCH"), // 编辑项目 （CRM管理员、销售）
    reqBusinessProjectEdit: (data) =>
        ajax("/api/crm/businessopportunity/all", data, "PATCH"), //项目合同管理员编辑项目 （CRM管理员、项目合同管理员）
    reqBusinessApply: (data) =>
        ajax("/api/crm/businessopportunity/apply", data, "PATCH"), // 成本申请 （销售）
    reqBusinessSelf: (data) =>
        ajax("/api/crm/businessopportunity/self", data, "GET"), // 获取自己的项目列表 （销售）
    reqBusinessDel: (data) =>
        ajax("/api/crm/businessopportunity", data, "DELETE"), // 删除项目 （CRM管理员）
    reqBusinessTransfer: (data) =>
        ajax("/api/crm/businessopportunity/transfer", data, "POST"), // 转移项目 （销售、公司管理）
    reqBusinessRenew: (data) =>
        ajax(
            "/api/crm/businessopportunity/application/expiration_time",
            data,
            "POST"
        ), //延期申请（销售）
    reqBusinessDelWinApply: (data) =>
        ajax("/api/crm/businessopportunity/withdraw/win", data, "PATCH"), //撤回赢单申请（销售）
    reqContractApply: (data) =>
        ajax("/api/crm/businessopportunity/application/contract", data, "POST"), // 合同申请（销售、合同管理员、合同操作员）
    reqContractApplyEdit: (data) =>
        ajax("/api/crm/businessopportunity/application/contract", data, "PATCH"), // 编辑合同申请（销售、合同管理员、合同操作员、合同扫描上传员）
    reqContractNumber: (data) =>
        ajax("/api/crm/businessopportunity/contract/number", data, "GET"), // 获取合同编号（销售、合同管理员、合同操作员）
    reqBusinessTag: (data) => ajax("/api/crm/businessdepartment", data, "GET"), //获取费用出处部门信息 （CRM管理员、销售）
    reqBusinessTagAdd: (data) =>
        ajax("/api/crm/businessdepartment", data, "POST"), //创建费用出处部门信息 （CRM管理员）
    reqCheckUpstream: (data) =>
        ajax("/api/crm/businessopportunity/upstream_cost_reason", data, "GET"), //查询项目里上游成本与采购合同金额是否一致 （销售，CRM管理员）
    reqUnlockCheck: (data) => ajax("/api/crm/businessopportunity/pipeline", data, "PATCH"), //解锁进铁笼的项目（项目解锁员）
    reqBusinessFinished: (data) => ajax("/api/crm/businessopportunity/finished", data, "PATCH"), //修改项目完结状态（修改项目完结状态）
    reqBusinessRemarksEdit: (data) => ajax("/api/crm/businessopportunity/remarks", data, "PATCH"),//修改项目备注（公司管理、销售、项目经理）
    reqBusinessExport: (data) => ajax("/api/crm/businessopportunity/self", data, "GET", { responseType: 'blob' }),//导出项目数据（销售、项目经理）

    // 合同管理
    reqContractSelf: (data) => ajax("/api/crm/contract/self", data, "GET"), // 获取自己的合同列表 （销售）
    reqContractList: (data) => ajax("/api/crm/contract/list", data, "GET"), // 管理员获取合同列表 （CRM管理员、MOA管理员、合同管理操作员、HRBP、HRBP助理）
    reqContractAdd: (data) => ajax("/api/crm/contract", data, "POST"), // 添加合同 （CRM管理员、MOA管理员、合同管理操作员、销售）
    reqContractDetail: (data) => ajax("/api/crm/contract", data, "GET"), // 获取合同详情 （CRM管理员、MOA管理员、合同管理操作员、HRBP、HRBP助理、销售）
    reqContractEdit: (data) => ajax("/api/crm/contract", data, "PATCH"), // 编辑合同 （CRM管理员、MOA管理员、合同管理操作员、销售）
    reqContractDel: (data) => ajax("/api/crm/contract", data, "DELETE"), // 删除合同 （CRM管理员、销售、MOA管理员、合同管理操作员）
    reqDownload: (data) =>
        ajax("/api/crm/application/contract/download", data, "PATCH"), //合同文件已下载（MOA管理员、合同盖章员）
    reqUpload: (data) =>
        ajax("/api/crm/application/contract/upload", data, "PATCH"), //前台合同上传结束 （MOA管理员、合同扫描上传员）
    reqContractApplyDel: (data) =>
        ajax("/api/crm/application/contract/delete", data, "DELETE"), //销售撤回合同申请 （销售）
    reqContactsCollection: (data) =>
        ajax("/api/crm/contract/collection", data, "PATCH"), //销售合同添加回款信息 （CRM管理员，销售）
    reqLastInvoice: (data) => ajax("/api/crm/invoice/contract", data, "GET"), //查询上一批次发票信息（CRM管理员、销售）
    reqInvoiceApply: (data) =>
        ajax("/api/crm/contract/application", data, "POST"), //发票申请（CRM管理员、销售）
    reqInvoiceEdit: (data) =>
        ajax("/api/crm/contract/application", data, "PATCH"), //修改发票申请（CRM管理员、销售）
    reqInvoicePackageEdit: (data) => ajax("/api/crm/application/express", data, "PATCH"), //发票快递信息录入快递单号等（CRM管理员、发票快递信息录入员）
    reqMarketingCostAdd: (data) => ajax('/api/crm/marketing_apply', data, 'POST'), //费用申请（crm管理员、销售）
    reqMarketingSelfList: (data) => ajax('/api/crm/marketing_apply/self/list', data, 'GET'), //销售获取自己的费用申请列表（crm管理员、销售）
    reqMarketingBusinessList: (data) => ajax('/api/crm/marketing_apply/businessopportunity/list', data, 'GET'), //项目详情获取费用申请列表（crm管理员、销售）
    reqMarketingCostApplyList: (data) => ajax('/api/crm/marketing_apply/apply/list', data, 'GET'), //审批员获取费用申请列表（CRM管理员、费用总监审批员、费用副总裁审批员、费用财务审批员）
    reqMarketingCostManagerApplyList: (data) => ajax('/api/crm/marketing_apply/manager/apply/list', data, 'GET'), //商务部总经理获取费用申请列表（CRM管理员、商务部总经理）
    reqMarketingAdminList: (data) => ajax('/api/crm/marketing_apply/admin/list', data, 'GET'), //管理员获取费用申请列表（crm管理员）
    reqMarketingBusinessCheck: (data) => ajax('/api/crm/marketing_apply/department/examine', data, 'PATCH'), //费用申请总监审批（crm管理员、费用总监审批员）
    reqMarketingManagerCheck: (data) => ajax('/api/crm/marketing_apply/manager/examine', data, 'PATCH'), //费用商务部总经理监审批（crm管理员、商务部总经理）
    reqMarketingAdminCheck: (data) => ajax('/api/crm/marketing_apply/admin/examine', data, 'PATCH'), //费用申请副总裁审批（crm管理员、费用副总裁审批员）
    reqMarketingFinanceCheck: (data) => ajax('/api/crm/marketing_apply/finance/examine', data, 'PATCH'), //费用申请财务审批（crm管理员、费用财务审批员）
    reqMarketingSelfCheck: (data) => ajax('/api/crm/marketing_apply/self/examine', data, 'PATCH'), //费用申请销售确认给出（crm管理员、销售）
    reqMarketingRefund: (data) => ajax('/api/crm/marketing_apply/refund', data, 'POST'), //费用退回（费用最终确认）

    // 小队管理
    reqTeamAdd: (data) => ajax("/api/crm/team", data, "POST"), // 添加小队队 （CRM管理员、HRBP、HRBP助理）
    reqTeamEdit: (data) => ajax("/api/crm/team", data, "PATCH"), // 编辑小队 （CRM管理员、HRBP、HRBP助理）
    reqTeamList: (data) => ajax("/api/crm/team/list", data, "GET"), // 获取小队列表 （CRM管理员、HRBP、HRBP助理）
    reqTeamDetail: (data) => ajax("/api/crm/team", data, "GET"), // 获取小队详情 （CRM管理员、HRBP、HRBP助理）
    reqTeamDel: (data) => ajax("/api/crm/team", data, "DELETE"), // 删除小队 （CRM管理员、HRBP）
    reqTeamSelf: (data) => ajax("/api/crm/team/self", data, "GET"), // 获取自己的小队 （销售）
    // 审批管理
    reqApplyList: (data) => ajax("/api/crm/application/list", data, "GET"), // 获取审批列表 （CRM管理员、HRBP、HRBP助理、财务总监、项目经理技术支持、费用审批、安服部审批、合同技术审核员、合同法务审核员、合同盖章员、最终合同审核员、合同扫描上传员）
    reqContractApplyList:(data)=>ajax(`/api/crm/application/contract/examine/list`,data,'GET'),//获取合同审批列表
    reqCostApplyList:(data)=>ajax(`/api/crm/application/cost/examine/list`,data,'GET'),//获取成本审批列表
    reqApplyJackList: (data) => ajax("/api/crm/application/A_affairs/list", data, "GET"), // 获取审批列表,有玖克的成本审批列表 （CRM管理员、HRBP、HRBP助理、财务总监、项目经理技术支持、费用审批、安服部审批、合同技术审核员、合同法务审核员、合同盖章员、最终合同审核员、合同扫描上传员）
    reqMyApplyList: (data) => ajax("/api/crm/application/self/list", data, "GET"), //获取我的审批列表,目前应用在合同申请 （CRM管理员、销售）
    reqApplyDetail: (data) => ajax("/api/crm/application", data, "GET"), // 获取审批详情 （CRM管理员、HRBP、HRBP助理、财务总监、项目经理技术支持、费用审批、安服部审批、合同技术审核员、合同法务审核员、合同盖章员、最终合同审核员、合同扫描上传员）
    reqApplyServiceCheck: (data) =>
        ajax("/api/crm/application/check_cost", data, "PATCH"), // 安服部审批 （CRM管理员、安服部审批）
    reqApplyServiceDetail: (data) =>
        ajax("/api/crm/application/check_detailed", data, "PATCH"), // 成本审批编辑产品明细 （CRM管理员、团队总监、销售、费用审批、武器库各审批员）
    reqApplyCostSellerContent: (data) =>
        ajax("/api/crm/application/check_detailed/seller", data, "PATCH"), // 成本审批,销售提交驳回说明 （CRM管理员、销售）
    reqSellerApplyServiceDetail: (data) =>
        ajax("/api/crm/application/check_detailed/marketing_cost", data, "PATCH"), // 成本审批，销售提交费用 （销售）
    reqMarketCostCheck: (data) =>
        ajax("/api/crm/application/check_detailed/market", data, "PATCH"), // 成本审批，审批费用（CRM管理员、费用审批、团队总监、商务部总经理）
    reqPreSellerCostCheck:(data)=>ajax(`/api/crm/application/check_detailed/project_manager`,data,"PATCH"),//成本审批，项目经理审批
    reqAdminCostCheck: (data) =>
        ajax("/api/crm/application/check_detailed/admin", data, "PATCH"), // 成本审批，商务部总经理审批（CRM管理员、商务部总经理）
    reqChannelCostCheck: (data) => ajax("/api/crm/application/check_detailed/channel", data, "PATCH"), // 成本审批，渠道审批（CRM管理员、渠道审批节点）
    reqFinalCostCheck: (data) =>
        ajax("/api/crm/application/check_detailed/final", data, "PATCH"), // 成本审批，最终审批（CRM管理员、成本最终审批员）
    reqReturnCostCheck: (data) =>
        ajax(
            "/api/crm/application/check_detailed/return_to_factory",
            data,
            "PATCH"
        ), // 成本审批，审批公司均摊成本（CRM管理员、团队总监）
    reqOtherCostCheck: (data) =>
        ajax(
            "/api/crm/application/check_detailed/other_cost",
            data,
            "PATCH"
        ), //其他成本审批
    reqInspectorCheck: (data) =>
        ajax(
            "/api/crm/application/check_detailed/inspector",
            data,
            "PATCH"
        ), //商务部总监审批分配贡献度
    reqProjectManagerCheck: (data) =>
        ajax(
            "/api/crm/application/check_detailed/project_manager",
            data,
            "PATCH"
        ), //项目经理确认
    reqSellerCheck: (data) =>
        ajax(
            "/api/crm/application/check_detailed/seller",
            data,
            "PATCH"
        ), //销售确认
    reqCheckProgress:(data)=>
        ajax(
            "/api/crm/application/progress",
            data,
            'GET'
        ),//获取审批流程
    reqWeaponCostCheck: (data) =>
        ajax("/api/crm/application/check_detailed/cost_new", data, "PATCH"), // 成本审批，审批武器内容（CRM管理员、武器库各审批员）
    reqWeaponDetailedAdd: (data) =>
        ajax("/api/crm/arsenal_detailed", data, "POST"), //成本审批创建产品或服务明细 (CRM管理员、武器库录入员)
    reqWeaponDetailedEdit: (data) =>
        ajax("/api/crm/arsenal_detailed", data, "PATCH"), //成本审批修改产品或服务明细 (CRM管理员、武器库录入员)
    reqWeaponDetailedDelete: (data) =>
        ajax("/api/crm/arsenal_detailed", data, "DELETE"), //成本审批删除产品或服务明细 (CRM管理员、武器库录入员)
    reqApplyCTOCheck: (data) =>
        ajax("/api/crm/application/check_cost_market", data, "PATCH"), // 费用审批 （CRM管理员、费用审批）
    reqWinList: (data) => ajax("/api/crm/businessopportunity/win", data, "GET"), // 赢单的项目列表 （CRM管理员、HRBP、HRBP助理、财务总监）
    reqBusinessPaymentList: (data) => ajax("/api/crm/businessopportunity/payment/admin", data, "GET"), // 管理员查看回款的项目列表 （管理员、回款管理、设置项目完结）
    reqReturnedMoneyCheck: (data) =>
        ajax("/api/crm/businessopportunity/returned_money", data, "PATCH"), // 回款审批 （CRM管理员、HRBP、HRBP助理、财务总监）
    reqWorkLogCheckList: (data) => ajax("/api/crm/worklog/list", data, "GET"), // 待审批照片打卡列表 （管理员）
    reqWorkLogCheckManagerList: (data) => ajax("/api/crm/worklog/manager/list", data, "GET"), // 待审批照片打卡列表，商务部总经理审批 （管理员、商务部总经理）
    reqWorkLogCheck: (data) => ajax("/api/crm/worklog/review", data, "PATCH"), // 审批照片打卡 （管理员）
    reqFilterList: (data) =>
        ajax("/api/crm/worklog/work_screen/list", data, "GET"), // 照片打卡获取筛选列表 （管理员）
    reqApplyProductList: (data) => ajax("/api/crm/application/cp2/list", data, "GET"), // 获取审批列表,产品审批员 （产品审批员）
    reqApplyServiceList: (data) => ajax("/api/crm/application/aqfw/list", data, "GET"), // 获取审批列表,服务审批员 （服务审批员）
    reqApplyOperateList: (data) => ajax("/api/crm/application/yy/list", data, "GET"), // 获取审批列表,运营审批员 （运营审批员）
    reqApplyOtherList: (data) => ajax("/api/crm/application/qt/list", data, "GET"), // 获取审批列表,其他审批员 （其他审批员）
    reqApplyBusinessList: (data) => ajax("/api/crm/application/shw/list", data, "GET"), // 获取审批列表,安全意识审批员 （安全意识审批员）
    reqApplyProduct1List: (data) => ajax("/api/crm/application/cpyb/list", data, "GET"), // 获取审批列表,产品一审批员 （产品一审批员）
    reqApplyProductCheck: (data) => ajax("/api/crm/application/cp2/pipeline/examine", data, "PATCH"), // 交付审批,产品审批员 （产品审批员）
    reqApplySecurityServiceCheck: (data) => ajax("/api/crm/application/aqfw/pipeline/examine", data, "PATCH"), // 交付审批,服务审批员 （服务审批员）
    reqApplyOperateCheck: (data) => ajax("/api/crm/application/yy/pipeline/examine", data, "PATCH"), // 交付审批,运营审批员 （运营审批员）
    reqApplyOtherCheck: (data) => ajax("/api/crm/application/qt/pipeline/examine", data, "PATCH"), // 交付审批,其他审批员 （其他审批员）
    reqApplyBusinessCheck: (data) => ajax("/api/crm/application/shw/pipeline/examine", data, "PATCH"), // 交付审批,安全意识审批员 （安全意识审批员）
    reqApplyProduct1Check: (data) => ajax("/api/crm/application/cpyb/pipeline/examine", data, "PATCH"), // 交付审批,产品一审批员 （产品一审批员）

    reqApplicationExport: (data) => ajax("/api/crm/application/law-approve/export", data, "GET", { responseType: 'blob' }),//导出法务审批数据

    //外出申请
    reqGoOutCTOList: (data) => ajax("/api/crm/application/go_out/xuesong/list", data, "GET"), // 出门卡审批列表CTO(雪松)审批 （CRM管理员、出门卡审批员）
    reqGoOutCEOList: (data) => ajax("/api/crm/application/go_out/luo/list", data, "GET"), // 出门卡审批列表，CEO(罗)审批 （CRM管理员、出门卡审批员）
    reqGoOutCOOList: (data) => ajax("/api/crm/application/go_out/xiaonan/list", data, "GET"), // 出门卡审批列表，COO(晓南)审批 （CRM管理员、出门卡审批员）
    reqGoOutInspecorList: (data) => ajax("/api/crm/application/go_out/inspector/list", data, "GET"), // 出门卡审批列表，总监审销售的审批 （CRM管理员、团队总监）
    reqGoOutManagerList: (data) => ajax("/api/crm/application/go_out/manager/list", data, "GET"), // 出门卡审批列表，总经理审总监的审批 （CRM管理员、商务部总经理）
    reqGoOutCheck: (data) => ajax("/api/crm/application/go_out/examine", data, "PATCH"), //出门卡审批 （CRM管理员、出门卡审批员、团队总监、商务部总经理）
    reqGoOutDailyList: (data) => ajax("/api/crm/application/go_out/one_day/situation", data, "GET"), //查询打卡人员、销售一天的出门卡审批列表 （CRM管理员、出门卡审批员、团队总监、商务部总经理）
    reqMyGoOutList: (data) => ajax("/api/crm/application/go_out/user/list", data, "GET"), //我的出门卡申请列表 （CRM管理员、销售）
    reqGoOutASSList: (data) => ajax("/api/crm/application/go_out/zhangqi/list", data, "GET"), // 出门卡审批列表，ASS(张琦)审批 （出门卡审批员）
    reqGoOutApply: (data) => ajax("/api/crm/application/go_out", data, "POST"), //出门申请（销售）
    reqGoOutRevoke: (data) => ajax("/api/crm/application/go_out/revoke", data, "PATCH"), //撤销出门申请（销售）

    // 申请书
    reqApplyAdd: (data) => ajax("/api/crm/application", data, "POST"), // 提交申请书 （销售）
    reqApplyEdit: (data) => ajax("/api/crm/application", data, "PATCH"), // 编辑申请书 （发票快递信息录入员）
    reqApplyCheck: (data) => ajax("/api/crm/application/examine", data, "PATCH"), // 审批申请书 （CRM管理员、HRBP、HRBP助理、财务总监、项目经理技术支持、合同技术审核员、合同法务审核员、最终合同审核员、合同扫描上传员、发票安服审批）
    reqApplyDelete: (data) => ajax("/api/crm/application", data, "DELETE"), // 撤回申请书 （销售）
    // 行为分
    reqBehaviorSelf: (data) => ajax("/api/crm/behavior/self", data, "GET"), // 获取自己的行为分列表 （销售）
    reqBehaviorEdit: (data) => ajax("/api/crm/behavior", data, "PATCH"), // 管理销售行为分 （CRM管理员、HRBP、HRBP助理）
    reqBehaviorList: (data) => ajax("/api/crm/behavior/list", data, "GET"), // 获取销售行为分列表 （CRM管理员、HRBP、HRBP助理）
    // 奖金池
    reqBonusAdd: (data) => ajax("/api/crm/bonus", data, "POST"), // 创建奖金记录 （CRM管理员、HRBP、HRBP助理）
    reqBonusList: (data) => ajax("/api/crm/bonus/list", data, "GET"), // 获取奖金列表 （CRM管理员、HRBP、HRBP助理）
    reqBonusSelf: (data) => ajax("/api/crm/bonus/self", data, "GET"), // 获取个人奖金列表 （销售）
    reqRedPacketAdd: (data) => ajax("/api/crm/bonus/lottery", data, "POST"), // 创建打卡红包记录（CRM管理员、HRBP、HRBP助理）
    reqRedPacketEdit: (data) => ajax("/api/crm/bonus", data, "PATCH"), // 修改打卡红包记录状态（CRM管理员、HRBP、HRBP助理）
    // 信誉分
    reqCreditAdd: (data) => ajax("/api/crm/credit", data, "POST"), // 添加信誉分 （CRM管理员、HRBP、HRBP助理）
    reqCreditList: (data) => ajax("/api/crm/credit/list", data, "GET"), // 获取信誉分列表 （CRM管理员、HRBP、HRBP助理）
    reqCreditSelf: (data) => ajax("/api/crm/credit/self", data, "GET"), // 获取个人信誉分列表 （销售）
    // 排行榜
    reqRankTeam: (data) => ajax("/api/crm/team/ranking_list", data, "GET"), // 获取小队排行榜 （CRM管理员、销售）
    // 漏斗图
    reqFunnelSelf: (data) =>
        ajax("/api/crm/businessopportunity/funnels/self", data, "GET"), // 获取销售漏斗图 （销售）
    // 项目列表
    reqBusinessFunnel: (data) =>
        ajax("/api/crm/businessopportunity/funnel/self", data, "GET"), // 获取销售项目列表 （销售）
    // 漏斗图
    reqFunnelAdmin: (data) =>
        ajax("/api/crm/businessopportunity/funnels/admin", data, "GET"), // 管理员获取销售漏斗图 （CRM管理员、公司管理）
    // 项目列表
    reqBusinessAdmin: (data) =>
        ajax("/api/crm/businessopportunity/funnel/admin", data, "GET"), // 管理员获取销售项目列表 （CRM管理员、公司管理）
    // 工作日志总览
    reqWorkLogView: (data) => ajax("/api/crm/worklog/view", data, "GET"), // 管理员获取工作日志总览 （CRM管理员）
    // 小队成员工作日志总览
    reqWorkLogTeamView: (data) => ajax("/api/crm/worklog/team_view", data, "GET"), // 管理员获取小队成员工作日志总览 （CRM管理员）
    // 客户标签
    reqClientTagList: (data) => ajax("/api/crm/customer/tags", data, "GET"),  // 获取标签列表 （销售）
    reqClientTagDel: (data) => ajax("/api/crm/customer/tags", data, "DELETE"), // 删除标签 （销售）
    reqClientTagAdd: (data) => ajax("/api/crm/customer/batch/tags", data, "PATCH"), // 批量修改标签 （公司管理）
    // 公司管理
    reqWinCount: (data) =>
        ajax("/api/crm/businessopportunity/win/count", data, "GET"), // 管理员查看赢单的统计数据 （公司管理）
    reqCustomerContacted: (data) =>
        ajax("/api/crm/customer/contacted", data, "GET"), // 管理员获取各行业客户覆盖率数据 （公司管理）
    reqManageSeller: (data) =>
        ajax("/api/crm/businessopportunity/win/sellers", data, "GET"), // 管理员查看销售业绩数据 （公司管理）
    // 项目经理管理
    reqPreSellerList: (data) =>
        ajax("/api/crm/pre_sales_support/users", data, "GET"), // 获取项目经理列表 （CRM管理员、销售）
    reqPreSellerSchedule: (data) =>
        ajax("/api/crm/pre_sales_support", data, "GET"), // 获取项目经理行程列表 （CRM管理员、项目经理技术支持、销售）
    reqPreSellerAdd: (data) =>
        ajax("/api/crm/pre_sales_support/application", data, "POST"), // 销售申请项目经理 （销售）
    reqPreSellerApplyList: (data) =>
        ajax("/api/crm/pre_sales_support/applications", data, "GET"), // 项目经理查看申请列表 （项目经理技术支持）
    reqPreSellerApplyDetail: (data) =>
        ajax("/api/crm/pre_sales_support/application", data, "GET"), // 项目经理查看申请详情 （CRM管理员、项目经理技术支持）
    reqPreSellerApplyRequest: (data) =>
        ajax("/api/crm/pre_sales_support/application", data, "PATCH"), // 新项目经理审批 （CRM管理员、项目经理管理员）
    reqAdminPreSellerApplyList: (data) =>
        ajax("/api/crm/pre_sales_support/admin", data, "GET"), // 管理查看所有项目经理申请 （CRM管理员）
    reqClientPreSellerList: (data) =>
        ajax("/api/crm/pre_sales_support/list", data, "GET"), //根据客户id获取项目经理列表 （CRM管理员、销售）
    reqPreSellerUserList: (data) =>
        ajax("/api/crm/pre_sales_support/users", data, "GET"), //驳回申请时,获取指派项目经理人员列表
    reqPreSalesUserList:(data)=>
        ajax(`/api/crm/pre_sales/user/list`,data,'GET'),//获取售前人员列表
    reqPreSellerCheck: (data) => ajax("/api/crm/pre_sales_support/applications/history", data, "GET"), //项目经理申请，是否有正在审批中的申请 （CRM管理员、销售）
    reqPreSellerSelfHistory: (data) =>
        ajax('/api/crm/pre_sales_support/seller/list', data, 'GET'), // 查看项目经理申请的历史（CRM管理员、销售）
    reqPreSellerHistoryInvoke: (data) =>
        ajax('/api/crm/pre_sales_support', data, 'PATCH'), // 发起项目经理申请历史撤回申请（CRM管理员、销售）
    reqPreSellerSelfApply: (data) =>
        ajax('/api/crm/pre_sales_support/applications/self', data, 'GET'), // 查看项目经理申请工单（CRM管理员、销售）
    reqPreSellerInvoke: (data) =>
        ajax('/api/crm/pre_sales_support/application', data, 'DELETE'), // 撤回项目经理申请工单（CRM管理员、销售）
    reqAdminPreSellerInvoke: (data) =>
        ajax('/api/crm/pre_sales_support/admin/list', data, 'GET'), // 项目经理管理查看项目经理撤回申请（CRM管理员、项目经理管理）
    reqAdminPreSellerInvokeApproval: (data) =>
        ajax('/api/crm/pre_sales_support/admin', data, 'PATCH'), // 项目经理管理 审批项目经理撤回申请 （CRM管理员、项目经理管理）

    //新项目经理申请
    reqNewPreSellerApply: (data) => ajax("/api/crm/project_manager/application", data, "POST"), //新项目经理申请（销售）
    reqNewPreSellerApplyDetail: (data) => ajax("/api/crm/project_manager/application", data, "GET"), //新项目经理申请详情（销售）
    reqNewPreSellerApplyList: (data) => ajax("/api/crm/project_manager/application/examine/list", data, "GET"), //新项目经理申请审批列表（项目经理管理）
    reqNewPreSellerApplyMyList: (data) => ajax("/api/crm/project_manager/application/list", data, "GET"), //我的项目经理审批列表（项目经理管理）
    reqNewPreSellerCheck: (data) => ajax("/api/crm/project_manager/application/examine", data, "POST"), //新项目经理申请审批（销售）
    reqNewPreSellerRevoke: (data) => ajax("/api/crm/project_manager/application/revoke", data, "POST"), //新项目经理申请撤回（销售）

    //售前申请
    resPreSalesApplyList:(data)=>ajax(`/api/crm/pre_sales/application/examine/list`,data,'GET'),//获取售前申请审批列表
    resPreSalesApplyMyList:(data)=>ajax(`/api/crm/pre_sales/application/list`,data,'GET'),//获取售前申请列表 
    reqPreSalesApplyDetail: (data) => ajax("/api/crm/pre_sales/application", data, "GET"), //新售前申请详情
    reqPreSaleCheck:(data)=>ajax(`/api/crm/pre_sales/application/examine`,data,'POST'),//售前审批

    // 项目费用管理
    reqBusinessCostList: (data) =>
        ajax("/api/crm/other_expenses/list", data, "GET"), // 获取项目费用列表 （CRM管理员、HRBP、HRBP助理、财务总监）
    reqBusinessCostAdd: (data) => ajax("/api/crm/other_expenses", data, "POST"), // 添加项目费用 （CRM管理员、HRBP、HRBP助理、财务总监）
    // 销售目标管理
    reqSellerTarget: (data) =>
        ajax("/api/crm/businessopportunity/win/cost", data, "GET"), // 管理员查看销售目标 （CRM管理员、团队总监、HRBP、HRBP助理）
    // 申诉管理
    reqComplaintAdd: (data) => ajax("/api/crm/appeal", data, "POST"), //销售提交打卡申诉 (销售)
    reqComplaintList: (data) => ajax("/api/crm/appeal/list", data, "GET"), // 申诉列表(管理员、特殊审批员)
    reqComplaintDetail: (data) => ajax("/api/crm/appeal", data, "GET"), // 申诉信息详情(销售、管理员、特殊审批员)
    reqComplaintCheck: (data) => ajax("/api/crm/appeal/review", data, "PATCH"), // 申诉审批(管理员、特殊审批员)
    reqComplaintSelf: (data) => ajax("/api/crm/appeal/self", data, "GET"), // 销售获取自己的申诉列表(销售)
    //回款管理
    reqReturnAdd: (data) => ajax("/api/crm/payment", data, "POST"), //添加回款 （CRM管理员、回款管理）
    reqReturnEdit: (data) => ajax("/api/crm/payment", data, "PATCH"), //修改回款，如：绑定发票 （CRM管理员、回款管理）
    reqReturnDetail: (data) => ajax("/api/crm/payment", data, "GET"), //回款详情 （CRM管理员、回款管理）
    reqReturnList: (data) => ajax("/api/crm/payment/list", data, "GET"), //回款列表 （CRM管理员、回款管理）
    reqMyReturnList: (data) => ajax("/api/crm/payment/user/list", data, "GET"), //我的回款列表 （CRM管理员、销售）
    reqInvoiceList: (data) => ajax("/api/crm/invoice/list", data, "GET"), //发票列表 （CRM管理员、回款管理）
    reqMyInvoiceList: (data) => ajax("/api/crm/invoice/user/list", data, "GET"), //我的发票列表 （CRM管理员、销售）
    reqInvoiceDetail: (data) => ajax("/api/crm/invoice", data, "GET"), //发票详情 （CRM管理员、回款管理）
    reqAdminInvoiceAdd: (data) => ajax("/api/crm/invoice", data, "POST"), //回款管理员创建发票 （CRM管理员、回款管理）
    reqAdminInvoiceEdit: (data) => ajax("/api/crm/invoice", data, "PATCH"), //回款管理员修改发票 （CRM管理员、回款管理）
    //项目管理
    reqCustomerNumber: (data) =>
        ajax("/api/crm/customer/project/statistics", data, "GET"), //项目管理获得每个级别客户数量，销售（CRM管理员、销售、团队总监）
    reqCustomerAdminNumber: (data) =>
        ajax("/api/crm/customer/admin/project/statistics", data, "GET"), //项目管理获得每个级别客户数量，管理（CRM管理员）
    reqProjectList: (data) =>
        ajax("/api/crm/customer/project/content", data, "GET"), //获取项目管理客户列表，销售（CRM管理员、销售、团队总监）
    reqProjectAdminList: (data) =>
        ajax("/api/crm/customer/admin/project/content", data, "GET"), //获取项目管理客户列表，管理（CRM管理员）
    reqUpgradeApply: (data) =>
        ajax("/api/crm/customer/application", data, "POST"), //客户等级提升申请（CRM管理员、销售）
    reqAdminUpgrade: (data) => ajax("/api/crm/customer/admin", data, "PATCH"), //管理直接提升客户等级（CRM管理员）

    //奖金赛
    reqSeasonDetail: (data) => ajax("/api/crm/match_season", data, "GET"), //查询赛季信息（CRM管理员、团队总监、销售）
    reqSellerContributionList: (data) =>
        ajax("/api/crm/match_season/contribution", data, "GET"), //查询销售贡献（CRM管理员、团队总监、销售）
    reqTeamRanklist: (data) => ajax("/api/crm/match_season/ranking", data, "GET"), //查询毛利排行榜（CRM管理员、团队总监、销售）
    reqAchievementList: (data) => ajax("/api/crm/achievement", data, "GET"), //查询成就列表（CRM管理员、团队总监、销售）
    reqDepartmentDetail: (data) => ajax("/api/crm/new_departments", data, "GET"), //查询事业部详情（CRM管理员、团队总监、销售）
    reqBattleAdd: (data) => ajax("/api/crm/battle/application", data, "POST"), //发起挑战（CRM管理员、团队总监）
    reqBattleApplicationList: (data) =>
        ajax("/api/crm/battle/application", data, "GET"), //查看审批列表（CRM管理员、团队总监）
    reqBattleNoAcList: (data) =>
        ajax("/api/crm/battle/application/not_accepted", data, "GET"), //查询未接受的挑战（CRM管理员、团队总监、销售）
    reqBattleList: (data) => ajax("/api/crm/battle", data, "GET"), //查询对战列表（CRM管理员、团队总监、销售）
    reqBattleBetAdd: (data) =>
        ajax("/api/crm/battle/application/bet", data, "POST"), //发起下注（CRM管理员、团队总监）
    reqApplicationRead: (data) =>
        ajax("/api/crm/application/battle", data, "PATCH"), //变更已查看的消息（CRM管理员、销售）
    reqNewDepartmentList: (data) =>
        ajax("/api/crm/new_departments/list", data, "GET"), //奖金赛查看所有团队（CRM管理员、销售）
    reqNewDepartmentAdd: (data) =>
        ajax("/api/crm/new_departments", data, "POST"), 
    reqNewDepartmentEdit: (data) =>
        ajax("/api/crm/new_departments", data, "PATCH"), 
    reqNewSellerList: (data) =>
        ajax("/api/crm/new_departments/suser/list", data, "GET"), //奖金赛查看所有销售人员（CRM管理员、销售）
    reqBattleCheck: (data) =>
        ajax("/api/crm/application/match/examine", data, "PATCH"), //销售接受或拒绝挑战（CRM管理员、销售）

    /*新日报*/
    reqDailyAdd: (data) => ajax("/api/crm/daily/new", data, "POST"), //提交日报（CRM管理员、销售）

    /*2022/10/09新日报*/
    reqNewDailyAdd: (data) => ajax("/api/crm/daily_work_report", data, "POST"), //提交日报（CRM管理员、销售）
    reqNewDailyList: (data) => ajax("/api/crm/daily_work_report/list", data, "GET"), //日报列表（CRM管理员、销售）
    reqNewDailyDetail: (data) => ajax("/api/crm/daily_work_report", data, "GET"), //日报详情（CRM管理员、销售）
    reqNewDailyWorkApplyList: (data) => ajax("/api/crm/daily_work_report/examine/list", data, "GET"), //新日报审批列表（公司管理）
    reqNewDailyCheck: (data) => ajax("/api/crm/daily_work_report/examine", data, "POST"), //日报审批（公司管理）
    reqNewDailyAutoActions: (data) => ajax("/api/crm/daily_work_report/auto_action", data, "GET"), //获取自动行动项（销售）
    reqNewDailyActionList: (data) => ajax("/api/crm/daily_work_report/action", data, "GET"), //行动项列表

    reqGetLocationList: (data) => ajax("/api/APP/location/get", data, "GET"), //获取定位信息列表（管理员）

    reqCommissionList: (data) => ajax("/api/crm/commission/list", data, "GET"), //获取项目提成钉钉审批列表 （CRM管理员、财务总监）
    reqCommissionDetail: (data) => ajax("/api/crm/commission/commission/list", data, "GET"), //获取项目提成详情列表 （CRM管理员、财务总监）
    reqCommissionRefresh: (data) => ajax("/api/crm/commission/commission/2022script", data, "GET"), //项目提成手动跑脚本,2022年（CRM管理员、财务总监）
    reqCommissionRefreshLast: (data) => ajax("/api/crm/commission/commission/2021script", data, "GET"), //项目提成手动跑脚本,2021年（CRM管理员、财务总监）

    reqAssistantEndRecordList: (data) => ajax("/api/crm/assistant_end_record/list", data, "GET"), //获取助理每天结束位置（管理员、出门卡审批员）

    /* 打卡考勤统计*/
    reqClockAttendanceList: (data) => ajax("/api/crm/application/attendance/list", data, "GET"), //打卡考勤列表（管理员、HRBP）
    reqClockAttendanceDetail: (data) => ajax("/api/crm/application/attendance/personal", data, "GET"), //打卡考勤详情（管理员、HRBP）
    reqAttendanceExport: (data) => ajax("/api/crm/worklog/statistics", data, "GET", { responseType: 'blob' }),//导出考勤统计数据（管理员、HRBP、HRBP助理）

    /*价值观考核*/
    reqBusinessValuesSettings: (data) => ajax("/api/crm/business_values_setting", data, "GET"), //获取商务价值观配置(管理员、销售)
    reqBusinessValuesSettingsEdit: (data) => ajax("/api/crm/business_values_setting", data, "PATCH"), //修改商务价值观配置(管理员，商务部总经理)
    reqBusinessValuesList: (data) => ajax("/api/crm/business_values/list", data, "GET"), //商务价值观列表(管理员，商务部总经理)
    reqBusinessValuesDetail: (data) => ajax("/api/crm/business_values", data, "GET"), //商务价值观详情(管理员，商务部总经理，销售)
    reqBusinessValuesAdd: (data) => ajax("/api/crm/business_values", data, "POST"), //添加商务价值观(管理员，商务部总经理)
    reqBusinessValuesEdit: (data) => ajax("/api/crm/business_values", data, "PATCH"), //修改商务价值观(管理员，商务部总经理)
    reqBusinessValuesExport: (data) => ajax("/api/crm/business_values/admin/export", data, "GET", { responseType: 'blob' }), //导出销售价值观(管理员，商务部总经理)

    reqMessageClientLAdd: (data) => ajax("/api/crm/customer/descriptions", data, "PATCH"), //添加客户描述（销售、公司管理）
    reqFollowSalesRemove: (data) => ajax("/api/crm/customer/follow_up_sales/remove", data, "POST"), //收回客户（公司管理）

    /* 文件审批 */
    reqFileApply: (data) => ajax("/api/crm/file_permission/application", data, "POST"), //合同文件申请（销售）
    reqFileApplyDetail: (data) => ajax("/api/crm/file_permission/application", data, "GET"), //合同文件申请详情（合同文件审批）
    reqFileApplyMyList: (data) => ajax("/api/crm/file_permission/application/list", data, "GET"), //我的合同文件申请列表（普通用户）
    reqFileApplyList: (data) => ajax("/api/crm/file_permission/application/examine/list", data, "GET"), //合同文件申请审批列表（合同文件审批）
    reqFileApplyCheck: (data) => ajax("/api/crm/file_permission/application/examine", data, "POST"), //合同文件申请审批（合同文件审批）
    reqFileApplyDelete: (data) => ajax("/api/crm/file_permission/application", data, "DELETE"), //合同文件申请删除（合同文件审批）

    /* 操作日志 */
    reqOperationLogList: (data) => ajax("/api/crm/operation_log/list", data, "GET"), //操作日志列表

    /*付款相关 */
    reqVendorList: (data) => ajax("/api/crm/vendor/list", data, "GET"), //供应商列表
    reqVendorAdd: (data) => ajax("/api/crm/vendor", data, "POST"), //添加供应商
    reqVendorEdit: (data) => ajax("/api/crm/vendor", data, "PATCH"), //修改供应商
    reqVendorDelete: (data) => ajax("/api/crm/vendor", data, "DELETE"), //删除供应商
    reqVendorDetail: (data) => ajax("/api/crm/vendor/detail", data, "GET"), //供应商详情
    reqVendorBusiness: (data) => ajax("/api/crm/vendor/projects", data, "GET"), //供应商关联项目列表

    reqDisbursementList: (data) => ajax("/api/crm/disbursement/list", data, "GET"), //付款列表
    reqDisbursementAdd: (data) => ajax("/api/crm/disbursement", data, "POST"), //添加付款
    reqDisbursementEdit: (data) => ajax("/api/crm/disbursement", data, "PATCH"), //修改付款
    reqDisbursementDelete: (data) => ajax("/api/crm/disbursement", data, "DELETE"), //删除付款
    reqDisbursementDetail: (data) => ajax("/api/crm/disbursement/detail", data, "GET"), //付款详情

    reqDisbursementInvoiceList: (data) => ajax("/api/crm/disbursement_invoice/list", data, "GET"), //付款发票列表
    reqDisbursementInvoiceAdd: (data) => ajax("/api/crm/disbursement_invoice", data, "POST"), //添加付款发票
    reqDisbursementInvoiceEdit: (data) => ajax("/api/crm/disbursement_invoice", data, "PATCH"), //修改付款发票
    reqDisbursementInvoiceDelete: (data) => ajax("/api/crm/disbursement_invoice", data, "DELETE"), //删除付款发票
    reqDisbursementInvoiceDetail: (data) => ajax("/api/crm/disbursement_invoice/detail", data, "GET"), //付款发票详情

    /* 报价单审批 */
    reqQuotationApplyAdd: (data) => ajax("/api/crm/quotation", data, "POST"), //报价单申请
    reqQuotationDetail: (data) => ajax("/api/crm/quotation/detail", data, "GET"), //报价单审批详情
    reqQuotationApplyList: (data) => ajax("/api/crm/quotation/list", data, "GET"), //报价单列表
    reqQuotationCheckList: (data) => ajax("/api/crm/quotation/examine/list", data, "GET"), //报价单审批列表
    reqQuotationCheck: (data) => ajax("/api/crm/quotation/examine", data, "POST"), //报价单审批
    reqQuotationRevoke: (data) => ajax("/api/crm/quotation/revoke", data, "POST"), //撤销报价单申请
    sellerUploadAttachment:(data)=>ajax(`/api/crm/quotation/examine/seller_upload`,data,'POST'),//销售上传报价单附件
    reqQuotationManagerCheck:(data)=>ajax(`/api/crm/quotation/examine/inspector`,data,'POST'),//销售总监审批

    /* 考勤记录 */
    reqAttendanceList: (data) => ajax("/api/crm/work_log_record/list", data, "GET"), //获取考勤记录列表
    reqAttendanceDetail: (data) => ajax("/api/crm/work_log_record", data, "GET"), //获取考勤记录详情
    reqAttendanceEdit: (data) => ajax("/api/crm/work_log_record", data, "PATCH"), //修改考勤记录
    reqAttendanceAdd: (data) => ajax("/api/crm/work_log_record/generate", data, "POST"), //生成考勤记录
    reqAttendanceExport: (data) => ajax("/api/crm/work_log_record/export", data, "GET", { responseType: 'blob' }), //导出考勤记录

    /* 薪资结算 */
    reqCompensation: (data) => ajax("/api/crm/level/getGrossProfitAndAmount", data, "GET"), //获取销售每个季度的总毛利
    reqCompensationLevelAdd: (data) => ajax("/api/crm/level/add", data, "POST"), //设置销售等级
    reqCompensationLevelEdit: (data) => ajax("/api/crm/level/update", data, "PATCH"), //修改销售等级
    reqCompensationProbation: (data) => ajax("/api/crm/seller/probationPeriod", data, "PATCH"), //设置试用期
    reqCompensationCommissionLog: (data) => ajax("/api/crm/commissionlog", data, "GET"), //查看已提成数据
    reqCompensationCommissionLogAdd: (data) => ajax("/api/crm/commissionlog", data, "POST"), //录入已提成数据
    reqCompensationCommissionLogEdit: (data) => ajax("/api/crm/commissionlog", data, "PATCH"), //修改已提成数据

    /**输单审批 */
    reqLoseApproveList:(data)=>ajax(`/api/crm/application/lose/examine/list`,data,'GET'),//获取输单审批列表
    reqLose:(data)=>ajax(`/api/crm/application/lose`,data,'POST'),//创建输单申请
    reqExamineLose:(data)=>ajax(`/api/crm/application/lose/examine`,data,'POST'),//审批输单申请
};

// moa接口
export const moaApi = {
    reqLogin: (data) => ajax("/api/dd/auth?sys=moa", data, "POST"), // 钉钉免登
    reqFundAdd: (data) => ajax("/api/moa/fund", data, "POST"), // 添加资金 （MOA管理员、资金管理操作员）
    reqFundList: (data) => ajax("/api/moa/fund/list", data, "GET"), // 获取资金列表 （MOA管理员、资金管理操作员）
    reqFundDetail: (data) => ajax("/api/moa/fund", data, "GET"), // 获取资金详情 （MOA管理员、资金管理操作员）
    reqFundEdit: (data) => ajax("/api/moa/fund", data, "PATCH"), // 编辑资金 （MOA管理员、资金管理操作员）
    reqFundDel: (data) => ajax("/api/moa/fund", data, "DELETE"), // 删除资金 （MOA管理员、资金管理操作员）
    reqUserList: (data) => ajax("/api/user/list", data, "GET"), // 获取用户列表
    reqAgencyList: (data) => ajax("/api/moa/agency/list", data, "GET"), // 获取代理机构列表 （MOA管理员、资金管理操作员、奖项管理操作员）
    reqAgencyAdd: (data) => ajax("/api/moa/agency", data, "POST"), // 添加代理机构 （MOA管理员、资金管理操作员、奖项管理操作员）
    reqAgencyDetail: (data) => ajax("/api/moa/agency", data, "GET"), // 获取代理机构详情 （MOA管理员、资金管理操作员、奖项管理操作员）
    reqAgencyEdit: (data) => ajax("/api/moa/agency", data, "PATCH"), // 编辑代理机构 （MOA管理员、资金管理操作员、奖项管理操作员）
    reqAgencyDel: (data) => ajax("/api/moa/agency", data, "DELETE"), // 删除代理机构 （MOA管理员、资金管理操作员、奖项管理操作员）
    reqArriveAdd: (data) => ajax("/api/moa/arrivedamount", data, "POST"), // 添加到账资金 （MOA管理员、资金管理操作员）
    reqArriveEdit: (data) => ajax("/api/moa/arrivedamount", data, "PATCH"), // 编辑到账资金 （MOA管理员、资金管理操作员）
    reqArriveList: (data) => ajax("/api/moa/arrivedamount/list", data, "GET"), // 获取到账资金列表 （MOA管理员、资金管理操作员）
    reqArriveDel: (data) => ajax("/api/moa/arrivedamount", data, "DELETE"), // 删除到账资金 （MOA管理员、资金管理操作员）
    reqArriveDetail: (data) => ajax("/api/moa/arrivedamount", data, "GET"), // 获取到账资金详情 （MOA管理员、资金管理操作员）

    reqExpenseAdd: (data) => ajax("/api/moa/expensesamount", data, "POST"), // 添加支出资金 （MOA管理员、资金管理操作员）
    reqExpenseEdit: (data) => ajax("/api/moa/expensesamount", data, "PATCH"), // 编辑支出资金 （MOA管理员、资金管理操作员）
    reqExpenseList: (data) => ajax("/api/moa/expensesamount/list", data, "GET"), // 获取支出资金列表 （MOA管理员、资金管理操作员）
    reqExpenseDel: (data) => ajax("/api/moa/expensesamount", data, "DELETE"), // 删除支出资金 （MOA管理员、资金管理操作员）
    reqExpenseDetail: (data) => ajax("/api/moa/expensesamount", data, "GET"), // 获取支出资金详情 （MOA管理员、资金管理操作员）

    reqContactsAdd: (data) => ajax("/api/moa/linkman", data, "POST"), // 添加联系人 （MOA管理员、资金管理操作员、奖项管理操作员）
    reqContactsEdit: (data) => ajax("/api/moa/linkman", data, "PATCH"), // 编辑联系人 （MOA管理员、资金管理操作员、奖项管理操作员）
    reqContactsList: (data) => ajax("/api/moa/linkman/list", data, "GET"), // 获取联系人列表 （MOA管理员、资金管理操作员、奖项管理操作员）
    reqContactsDel: (data) => ajax("/api/moa/linkman", data, "DELETE"), // 删除联系人 （MOA管理员、资金管理操作员、奖项管理操作员）
    reqContactsDetail: (data) => ajax("/api/moa/linkman", data, "GET"), // 获取联系人详情 （MOA管理员、资金管理操作员、奖项管理操作员）

    reqResponseAdd: (data) => ajax("/api/moa/responseunit", data, "POST"), // 添加负责单位 （MOA管理员、资金管理操作员）
    reqResponseEdit: (data) => ajax("/api/moa/responseunit", data, "PATCH"), // 编辑负责单位 （MOA管理员、资金管理操作员）
    reqResponseList: (data) => ajax("/api/moa/responseunit/list", data, "GET"), // 获取负责单位列表 （MOA管理员、资金管理操作员）
    reqResponseDel: (data) => ajax("/api/moa/responseunit", data, "DELETE"), // 删除负责单位 （MOA管理员、资金管理操作员）
    reqResponseDetail: (data) => ajax("/api/moa/responseunit", data, "GET"), // 获取负责单位详情 （MOA管理员、资金管理操作员）

    reqFundSchedule: (data) => ajax("/api/moa/fundschedule", data, "GET"), // 获取资金项目进度 （MOA管理员、资金管理操作员）
    reqFundScheduleEdit: (data) => ajax("/api/moa/fundschedule", data, "PATCH"), // 管理员修改资金项目进度内容 （MOA管理员）
    reqFundScheduleCommit: (data) =>
        ajax("/api/moa/fundschedule/commit", data, "PATCH"), // 管理员修改资金项目阶段 （MOA管理员）
    reqFundScheduleWorker: (data) =>
        ajax("/api/moa/fundschedule/teamworker", data, "PATCH"), // 协作者修改资金项目内容 （MOA管理员、资金管理操作员）
    reqFundScheduleJump: (data) =>
        ajax("/api/moa/fundschedule/jump", data, "PATCH"), // 管理员跳转资金项目阶段 （MOA管理员）

    reqMobileAdd: (data) =>
        ajax("/api/moa/platform_account_mobile", data, "POST"), // 添加手机号 （MOA管理员、账号管理操作员）
    reqMobileEdit: (data) =>
        ajax("/api/moa/platform_account_mobile", data, "PATCH"), // 编辑手机号 （MOA管理员、账号管理操作员）
    reqMobileList: (data) =>
        ajax("/api/moa/platform_account_mobile/list", data, "GET"), // 获取手机号列表 （MOA管理员、账号管理操作员）
    reqMobileDel: (data) =>
        ajax("/api/moa/platform_account_mobile", data, "DELETE"), // 删除手机号 （MOA管理员、账号管理操作员）
    reqMobileDetail: (data) =>
        ajax("/api/moa/platform_account_mobile", data, "GET"), // 获取手机号详情 （MOA管理员、账号管理操作员）

    reqAccountAdd: (data) => ajax("/api/moa/platform_account", data, "POST"), // 添加账号 （MOA管理员、账号管理操作员）
    reqAccountEdit: (data) => ajax("/api/moa/platform_account", data, "PATCH"), // 编辑账号 （MOA管理员、账号管理操作员）
    reqAccountList: (data) => ajax("/api/moa/platform_account/list", data, "GET"), // 获取账号列表 （MOA管理员、账号管理操作员）
    reqAccountDel: (data) => ajax("/api/moa/platform_account", data, "DELETE"), // 删除账号 （MOA管理员、账号管理操作员）
    reqAccountDetail: (data) => ajax("/api/moa/platform_account", data, "GET"), // 获取账号详情 （MOA管理员、账号管理操作员）

    reqMeetingAdd: (data) => ajax("/api/moa/meeting", data, "POST"), // 添加会议 （MOA管理员）
    reqMeetingEdit: (data) => ajax("/api/moa/meeting", data, "PATCH"), // 编辑会议 （MOA管理员）
    reqMeetingList: (data) => ajax("/api/moa/meeting/list", data, "GET"), // 获取会议列表 （MOA管理员）
    reqMeetingDel: (data) => ajax("/api/moa/meeting", data, "DELETE"), // 删除会议 （MOA管理员）
    reqMeetingDetail: (data) => ajax("/api/moa/meeting", data, "GET"), // 获取会议详情 （MOA管理员）

    reqMeetingContactAdd: (data) =>
        ajax("/api/moa/meeting/contact", data, "POST"), // 添加会议联系人 （MOA管理员）
    reqMeetingContactEdit: (data) =>
        ajax("/api/moa/meeting/contact", data, "PATCH"), // 编辑会议联系人 （MOA管理员）
    reqMeetingContactDel: (data) =>
        ajax("/api/moa/meeting/contact", data, "DELETE"), // 删除会议联系人 （MOA管理员）
    reqMeetingContactDetail: (data) =>
        ajax("/api/moa/meeting/contact", data, "GET"), // 获取会议联系人详情 （MOA管理员）

    reqAwardsSchedule: (data) => ajax("/api/moa/award_schedule", data, "GET"), // 获取奖项项目进度 （MOA管理员、奖项管理操作员）
    reqAwardsScheduleEdit: (data) =>
        ajax("/api/moa/award_schedule", data, "PATCH"), // 管理员修改奖项项目进度内容 （MOA管理员）
    reqAwardsScheduleCommit: (data) =>
        ajax("/api/moa/award_schedule/commit", data, "PATCH"), // 管理员修改奖项项目阶段 （MOA管理员）
    reqAwardsScheduleWorker: (data) =>
        ajax("/api/moa/award_schedule/teamworker", data, "PATCH"), // 协作者修改奖项项目内容 （MOA管理员、奖项管理操作员）
    reqAwardsScheduleJump: (data) =>
        ajax("/api/moa/award_schedule/jump", data, "PATCH"), // 管理员跳转奖项项目阶段 （MOA管理员）
    reqPlatformList: (data) => ajax("/api/moa/platform/list", data, "GET"), // 获取奖项平台列表 （MOA管理员、奖项管理操作员）
    reqPlatformAdd: (data) => ajax("/api/moa/platform", data, "POST"), // 添加奖项平台 （MOA管理员、奖项管理操作员）
    reqPlatformDetail: (data) => ajax("/api/moa/platform", data, "GET"), // 获取奖项平台详情 （MOA管理员、奖项管理操作员）
    reqPlatformEdit: (data) => ajax("/api/moa/platform", data, "PATCH"), // 编辑奖项平台 （MOA管理员、奖项管理操作员）
    reqPlatformDel: (data) => ajax("/api/moa/platform", data, "DELETE"), // 删除奖项平台 （MOA管理员、奖项管理操作员）
    reqAwardsAdd: (data) => ajax("/api/moa/award", data, "POST"), // 添加奖项 （MOA管理员、奖项管理操作员）
    reqAwardsEdit: (data) => ajax("/api/moa/award", data, "PATCH"), // 编辑奖项 （MOA管理员、奖项管理操作员）
    reqAwardsList: (data) => ajax("/api/moa/award/list", data, "GET"), // 获取奖项列表 （MOA管理员、奖项管理操作员）
    reqMyAwardsList: (data) => ajax("/api/moa/award/self/list", data, "GET"), // 获取自己的奖项列表 （MOA管理员、奖项管理用户）
    reqAwardsDetail: (data) => ajax("/api/moa/award", data, "GET"), // 获取奖项详情 （MOA管理员、奖项管理操作员）
    reqAwardsDel: (data) => ajax("/api/moa/award", data, "DELETE"), // 删除奖项 （MOA管理员、奖项管理操作员）

    reqTaskList: (data) => ajax("/api/moa/task", data, "GET"), // 管理员查看任务列表 （MOA管理员、公司管理）
    reqTaskAdd: (data) => ajax("/api/moa/task/add", data, "POST"), // 管理员添加任务 （MOA管理员、公司管理）
    reqTaskDetail: (data) => ajax("/api/moa/task/one", data, "GET"), // 管理员查看任务详情 （MOA管理员、公司管理）
    reqTaskEdit: (data) => ajax("/api/moa/task/update", data, "PATCH"), // 管理员编辑任务 （MOA管理员、公司管理）
    reqTaskDel: (data) => ajax("/api/moa/task", data, "DELETE"), // 管理员删除任务 （MOA管理员、公司管理）
    reqTaskSelf: (data) => ajax("/api/moa/task/list", data, "GET"), // 查看自己的任务列表 （任务负责人）
    /*点将台*/
    reqAbilityList: (data) => ajax("/api/moa/appointing/list", data, "GET"), // 管理员查看某部门所有评分 （MOA管理员、公司管理）
    reqAbilityEdit: (data) => ajax("/api/moa/appointing", data, "PATCH"), // 管理员编辑某部门所有评分 （MOA管理员、公司管理）

    //印章管理
    reqAdminSealList: (data) => ajax("/api/crm/seal/admin/list", data, "GET"), //管理员查看盖章申请列表 （MOA管理员、盖章管理员、盖章文件管理员）
    reqAdminSealConfirm: (data) => ajax("/api/crm/seal/upload", data, "PATCH"), //管理员确认盖章申请文件是否上传完毕（MOA管理员、盖章管理员）
    reqMySealList: (data) => ajax("/api/crm/seal/list", data, "GET"), //查看我的印章申请列表（MOA管理员、普通用户）
    reqSealUpload: (data) => ajax("/api/crm/seal/after_document", data, "PATCH"), //上传盖章文件（MOA管理员、普通用户）

};

// 公司管理接口
export const manageApi = {
    reqLogin: (data) => ajax("/api/dd/auth?sys=mgr", data, "POST"), // 钉钉免登
    reqClientManageList: (data) => ajax("/api/crm/customer/admin", data, "GET"), //客户管理统计数据
    reqExportExcel: (data) => ajax("/api/crm/customer/admin/export", data, "GET", { responseType: 'blob' }), //导出excel
};

// 培训系统接口
export const trainApi = {
    reqTrainCheck: (data) => ajax("/api/teach/check_in", data, "POST"), // 签到 （普通用户）
    reqTrainCheckList: (data) => ajax("/api/teach/check_in/list", data, "GET"), // 获取签到列表 （普通用户）
    reqTrainChat: (data) => ajax("/api/teach/chat", data, "POST"), // 提问 （普通用户）
    reqTrainChatList: (data) => ajax("/api/teach/chat/list", data, "GET"), // 获取答疑列表 （普通用户）
};

// 后台接口
export const adminApi = {
    reqRightList: (data) => ajax("/api/right/list", data, "GET"), // 获取权限列表 （后台管理员）
    reqRightAdd: (data) => ajax("/api/right/add", data, "POST"), // 添加权限 （后台管理员）
    reqRightDetail: (data) => ajax("/api/right/info", data, "GET"), // 获取权限详情 （后台管理员）
    reqRightEdit: (data) => ajax("/api/right", data, "PATCH"), // 编辑权限 （后台管理员）
    reqRightDel: (data) => ajax("/api/right", data, "DELETE"), // 删除权限 （后台管理员）
    reqRoleList: (data) => ajax("/api/role/list", data, "GET"), // 获取角色列表 （后台管理员）
    reqRoleAdd: (data) => ajax("/api/role", data, "POST"), // 添加角色 （后台管理员）
    reqRoleEdit: (data) => ajax("/api/role", data, "PATCH"), // 编辑角色 （后台管理员）
    reqRoleDel: (data) => ajax("/api/role", data, "DELETE"), // 删除角色 （后台管理员）
    reqRoleDetail: (data) => ajax("/api/role/self", data, "GET"), // 获取角色详情 （后台管理员）
    reqUserDetail: (data) => ajax("/api/user/info", data, "GET"), // 获取用户详情 （后台管理员）
    reqUserRoleEdit: (data) => ajax("/api/role/user", data, "PATCH"), // 编辑用户角色 （后台管理员）

    reqDeviceAdd: (data) => ajax('/api/APP/equipment/admin', data, "POST"), //添加设备 （后台管理员）
    reqDeviceEdit: (data) => ajax('/api/APP/equipment/admin/update', data, "PATCH"), //修改设备信息 （后台管理员）
    reqDeviceList: (data) => ajax('/api/APP/equipment/list', data, "GET"), //设备列表 （后台管理员）
    reqDeviceDetail: (data) => ajax('/api/APP/equipment/admin', data, "GET"), //设备详情 （后台管理员）
    reqVersionList: (data) => ajax('/api/APP/version/list', data, "GET"), //APP版本列表 （后台管理员）
    reqVersionAdd: (data) => ajax('/api/APP/version', data, "POST"), //添加APP版本 （后台管理员）
};

export const siteMessageApi = {
    reqMessageList: (data) => ajax("/api/message/site_message/list", data, "GET"),//站内信列表 （普通用户）
    reqUnreadMessageAmount: (data) => ajax("/api/message/site_message/unread_count", data, "GET"),//站内信未读信息数量 （普通用户
    reqMessageStatusChange: (data) => ajax("/api/message/site_message/status", data, "PATCH"),//标记站内信已读 （普通用户）
    reqMessageStatusDetail: (data) => ajax("/api/message/site_message", data, "GET"),//消息详情（普通用户）
    reqMessageAdd: (data) => ajax("/api/message/site_message/send", data, "POST"),//添加消息（管理员）
}

export const aiApi = {
    reqLogin: (data) => ajax("/api/dd/auth?sys=ai", data, "POST"), // 钉钉免登
    reqConversationList: (data) => ajax("/api/ai/conversation/list", data, "GET"), //会话列表
    reqConversationAdd: (data) => ajax("/api/ai/conversation", data, "POST", {}, true), //发送会话消息
    reqConversationEdit: (data) => ajax("/api/ai/conversation", data, "PATCH"), //修改会话标题
    reqConversationDetail: (data) => ajax("/api/ai/conversation/detail", data, "GET"), //会话详情
    reqConversationMessageList: (data) => ajax("/api/ai/conversation/messages", data, "GET"), //会话消息列表
    reqConversationDelete: (data) => ajax("/api/ai/conversation", data, "DELETE"), //删除会话
    reqConversationCount: (data) => ajax("/api/ai/common/usage", data, "GET"), //查询对话次数
    reqVoiceToTextUrl: (data) => ajax("/api/ai/voice/iat/url", data, "GET"), //获取语音转文字url
    reqTextToVoiceUrl: (data) => ajax("/api/ai/voice/tts/url", data, "GET"), //获取文字转语音url
    reqUsageStatistics: (data) => ajax("/api/ai/common/usage/statistics", data, "GET"), //使用量统计(公司管理)
}

//仪表盘数据
export const dashbordApi={
    reqTotal:(data) => ajax("/api/crm/dashboard/total", data, "GET"), // 获取总体数据
    reqCustomer:(data)=>ajax("/api/crm/dashboard/customer", data, "GET"), // 获取客户数据
    reqProduct:(data)=>ajax("/api/crm/dashboard/product", data, "GET"), // 获取产品数据
    reqDepartment:(data)=>ajax("/api/crm/dashboard/department", data, "GET"), // 获取部门数据
    reqSeller:(data)=>ajax("/api/crm/dashboard/seller", data, "GET"), // 获取销售个人数据
}

//销售数据
export const seller={
    reqGetTarget:(data)=>ajax(`/api/crm/seller_target/list`,data,"GET"),//获取销售目标
    reqSetTarget:(data)=>ajax(`/api/crm/seller_target/set`,data,"POST"),//设置销售目标
}

